import { Box, Center, useClipboard, useToast } from '@chakra-ui/react';
import React from 'react';
import { LoadingDots } from './loading-dots';

export default ({ valueToCopy, children, light }: { valueToCopy: string; children: JSX.Element; light?: boolean }) => {
    const toast = useToast();
    const { hasCopied, onCopy } = useClipboard(valueToCopy, 1000);

    return (
        <Box
            role="button"
            color={light ? 'white' : 'accent'}
            onClick={() => {
                onCopy();
                setTimeout(
                    () =>
                        toast({
                            description: (
                                <Center align="center">
                                    {`${valueToCopy.slice(0, 30)}...`} <br /> copied to clipboard
                                </Center>
                            ),
                            duration: 5000,
                            status: 'success',
                            position: 'top',
                            isClosable: true,
                        }),
                    500,
                );
            }}
            aria-label={`Copy ${valueToCopy}`}
        >
            {hasCopied ? <LoadingDots /> : children}
        </Box>
    );
};
