import { HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { FaEthereum } from 'react-icons/fa';
import { Ethereum } from '../../data-lib/ethereum';

type EtherProps = StackProps & { ether?: Ethereum };

export const Ether = ({ ether, ...props }: EtherProps) => {
    return (
        <HStack {...props} spacing="0px">
            <Icon as={FaEthereum} />
            {ether !== undefined && <Text>{Intl.NumberFormat('en-US', { minimumFractionDigits: 4 }).format(ether)}</Text>}
        </HStack>
    );
};
