import { HStack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';

type XDaiProps = StackProps & { xDaiValue?: number };
export const XDai = ({ xDaiValue, ...props }: XDaiProps) => {
    return (
        <HStack {...props} spacing="0px">
            {xDaiValue !== undefined ? (
                <Text>{`${Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(xDaiValue)} xDai`}</Text>
            ) : (
                <Text>$</Text>
            )}
        </HStack>
    );
};
