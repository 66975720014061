import { HStack, Image, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import CeloIcon from 'url:../../assets/celo-icon.svg';
type CeloProps = StackProps & { celo?: number };

export const Celo = ({ celo, ...props }: CeloProps) => {
    return (
        <HStack {...props} spacing="0px">
            <Image src={CeloIcon} />
            {celo !== undefined && <Text>{Intl.NumberFormat('en-US', { minimumFractionDigits: 4 }).format(celo)}</Text>}
        </HStack>
    );
};
