import { HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { FaBtc } from 'react-icons/fa';

type RBTCProps = StackProps & { rbtc?: number };
export const RBTC = ({ rbtc, ...props }: RBTCProps) => {
    return (
        <HStack {...props} spacing="0px">
            <Icon as={FaBtc} />
            {rbtc !== undefined && <Text>{Intl.NumberFormat('en-US', { minimumFractionDigits: 6 }).format(rbtc)}</Text>}
        </HStack>
    );
};
