import { SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    HStack,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Select,
    SlideFade,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import CalendarIcon from 'url:../../../assets/calendar.svg';
import { BullaClaimInfo } from '../../../data-lib/data-transforms';
import { CurrencyType, networks } from '../../../data-lib/networks';
import { useWeb3 } from '../../../hooks/useWeb3';
import { CurrencyIcon } from '../../currency/currency';
import DatePicker from '../../date-picker';
import { TableFilter } from './bulla-view';

type FilterValues = {
    search: string | undefined;
    date: { within?: Date; startDate?: Date; endDate?: Date };
    type: 'Invoice' | 'Remittance' | 'All' | undefined;
    priceRange: string | undefined;
};
type SetFilter = (key: keyof FilterValues, value: FilterValues[keyof FilterValues]) => void;
type SetDate = (type: 'startDate' | 'endDate' | 'within', date: Date | undefined) => void;
type WithinDays = { days?: number; label: string };
type TableFilters = TableFilter[] | [];
type SetClaimFilters = (filters: TableFilters) => void;
type InputChangeEvent = React.ChangeEvent<HTMLInputElement>;

export const currencyFilters: Record<CurrencyType, { label: string; filter: string }[]> = {
    ETH: [
        { label: 'less than .1 ETH', filter: '0-.1' },
        { label: '.1 ETH - .25 ETH', filter: '.1-.25' },
        { label: '.25 ETH - .5 ETH', filter: '.25-.5' },
        { label: '.5 ETH - 1 ETH', filter: '.5-1' },
        { label: 'Greater than 1 ETH', filter: '1-' },
    ],
    RBTC: [
        { label: 'Less than .01 RBTC', filter: '0-.01' },
        { label: '.01 RBTC - .025 RBTC', filter: '.01-.025' },
        { label: '.025 RBTC - .05 RBTC', filter: '.025-.05' },
        { label: '.05 RBTC - .1 RBTC', filter: '.05-.1' },
        { label: 'Greater than .1 RBTC', filter: '.1-' },
    ],
    XDAI: [
        { label: 'Less than $50', filter: '0-50' },
        { label: '$50 - $100', filter: '50-100' },
        { label: '$100 - $500', filter: '100-500' },
        { label: 'Greater than $500', filter: '500-' },
    ],
    CELO: [
        { label: 'Less than $50', filter: '0-50' },
        { label: '$50 - $100', filter: '50-100' },
        { label: '$100 - $500', filter: '100-500' },
        { label: 'Greater than $500', filter: '500-' },
    ],
};

const buildFilters = (filterValues: FilterValues) => {
    const {
        search,
        date: { startDate, endDate, within },
        priceRange,
        // type,
    } = filterValues;
    const filterByText = !!search
        ? (claim: BullaClaimInfo) => {
              if (!search) return true;
              const filterParameters = [
                  claim.bullaDescription,
                  claim.claimAddress,
                  claim.creditor,
                  claim.debtor,
                  claim.claimAmount,
                  claim.paidAmount,
                  claim.description,
              ];
              return filterParameters.some(value => String(value).toLowerCase().includes(search.trim().toLowerCase()));
          }
        : undefined;

    const filterByDate =
        !startDate && !endDate && !within
            ? undefined
            : ({ created: claimCreatedDate }: BullaClaimInfo) => {
                  if (startDate && endDate) return moment(claimCreatedDate).isBetween(startDate, endDate);
                  return moment(claimCreatedDate).isAfter(within);
              };
    const filterByPriceRange = !!priceRange
        ? ({ claimAmount }: BullaClaimInfo) => {
              const [startPrice, endPrice] = priceRange.split('-');
              if (!endPrice && claimAmount >= +startPrice) return true;
              if (claimAmount >= +startPrice && claimAmount <= +endPrice) return true;
          }
        : undefined;
    // const filterByType;
    return [
        filterByText,
        filterByDate,
        filterByPriceRange,
        //  filterByType
    ].filter(Boolean) as TableFilters;
};

export const DateSelect = ({
    openAdvancedFilters,
    setDate,
    resetDates,
    isUsingCustom,
}: {
    openAdvancedFilters: VoidFunction;
    setDate: SetDate;
    resetDates: VoidFunction;
    isUsingCustom: boolean;
}) => {
    const [withinDays, setWithinDays] = React.useState<WithinDays>({ label: 'all' });
    const handleSetWithinDays = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        switch (value) {
            case 'all':
                resetDates();
                setWithinDays({ label: value });
                break;
            case 'custom':
                openAdvancedFilters();
                setWithinDays({ label: 'custom' });
                setDate('within', undefined);
                break;
            default:
                const days = +value;
                setWithinDays({ label: value, days });
                setDate('within', moment().subtract(days, 'days').toDate());
        }
    };

    return (
        <>
            <Select
                onChange={handleSetWithinDays}
                value={isUsingCustom ? 'custom' : withinDays.label}
                maxW="fit-content"
                borderColor="rgba(138, 130, 122, 0.5)"
                focusBorderColor="rgba(138, 130, 122, 0.5)"
            >
                <option value="all">All Time</option>
                <option disabled={isUsingCustom} value="7">
                    7 days
                </option>
                <option disabled={isUsingCustom} value="30">
                    30 days
                </option>
                <option disabled={isUsingCustom} value="60">
                    60 days
                </option>
                <option disabled={isUsingCustom} value="90">
                    90 days
                </option>
                <option value="custom">Custom</option>
            </Select>
            <Box w="2" />
        </>
    );
};

const AdvancedFilters = ({ setDate, setFilter, filters }: { setDate: SetDate; setFilter: SetFilter; filters: FilterValues }) => {
    const [showCustomAmount, setShowCustomAmount] = React.useState(false);
    const [customAmount, setCustomAmount] = React.useState({ to: '', from: '' });
    const { network } = useWeb3();
    const currency = network && networks[network].currency;
    const { startDate, endDate } = filters.date;
    // const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => setFilter('type', e.target.value);
    const handleChangePriceRange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const priceRange = e.target.value ? e.target.value : undefined;
        switch (priceRange) {
            case 'all':
                setFilter('priceRange', undefined);
                setShowCustomAmount(false);
                break;
            case 'custom':
                setFilter('priceRange', undefined);
                setShowCustomAmount(true);
                break;
            default:
                setFilter('priceRange', priceRange);
        }
    };

    const getAmount = (e: InputChangeEvent) => e.target.value.replace('e', '');
    const handleFrom = (e: InputChangeEvent) => {
        const amount = getAmount(e);
        setCustomAmount(amounts => ({ ...amounts, from: amount }));
        setFilter('priceRange', `${amount}-${customAmount.to}`);
    };
    const handleTo = (e: InputChangeEvent) => {
        const amount = getAmount(e);
        setCustomAmount(amounts => ({ ...amounts, to: amount }));
        setFilter('priceRange', `${customAmount.from}-${amount}`);
    };

    return (
        <SlideFade in>
            <Stack h={'min-content'}>
                <HStack spacing="4" shouldWrapChildren>
                    <Text mr="-2">From</Text>
                    <InputGroup>
                        <DatePicker
                            selectedDate={startDate}
                            onChange={date => setDate('startDate', date)}
                            maxDate={endDate ?? new Date()}
                            dateFormat="dd MMM yyyy"
                            placeholderText="DD/MM/YYYY"
                        />
                        <InputRightElement h="100%" w="4em" pointerEvents="none" children={<Image src={CalendarIcon} />} />
                    </InputGroup>
                    <Text mr="-2">To</Text>
                    <InputGroup>
                        <DatePicker
                            selectedDate={endDate}
                            onChange={date => setDate('endDate', date)}
                            minDate={startDate}
                            maxDate={new Date()}
                            dateFormat="dd MMM yyyy"
                            placeholderText="DD/MM/YYYY"
                        />
                        <InputRightElement h="100%" w="4em" pointerEvents="none" children={<Image src={CalendarIcon} />} />
                    </InputGroup>
                    {/* <Select
                        value={filters['type']}
                        onChange={handleChangeType}
                        borderColor="rgba(138, 130, 122, 0.5)"
                        focusBorderColor="rgba(138, 130, 122, 0.5)"
                    >
                        <option value="All">All Types</option>
                        <option value="Invoice">Invoices</option>
                        <option value="Remittance">Remittances</option>
                    </Select> */}
                    <Text mr="-2">Amount</Text>
                    <Select
                        value={showCustomAmount ? 'custom' : filters['priceRange']}
                        onChange={handleChangePriceRange}
                        borderColor="rgba(138, 130, 122, 0.5)"
                        focusBorderColor="rgba(138, 130, 122, 0.5)"
                        minW="min-content"
                    >
                        <option value="all">Any Value</option>
                        {currency &&
                            currencyFilters[currency].map(({ filter, label }) => (
                                <option key={label} value={filter} disabled={showCustomAmount}>
                                    {label}
                                </option>
                            ))}
                        <option value="custom">Custom</option>
                    </Select>
                    {showCustomAmount && (
                        <HStack spacing="4">
                            <Text mr="-2">From</Text>
                            <InputGroup>
                                <InputLeftElement children={<CurrencyIcon />} />
                                <Input type="number" onChange={handleFrom} value={customAmount.from} />
                            </InputGroup>
                            <Text mr="-2">To</Text>

                            <InputGroup>
                                <InputLeftElement children={<CurrencyIcon />} />
                                <Input type="number" onChange={handleTo} value={customAmount.to} />
                            </InputGroup>
                        </HStack>
                    )}
                    <Spacer />
                </HStack>
            </Stack>
        </SlideFade>
    );
};

export default ({ setClaimFilters }: { setClaimFilters: SetClaimFilters }) => {
    const { onClose, isOpen: showAdvancedFilters, onOpen: openAdvancedFilters } = useDisclosure();
    const [filters, setFilters] = React.useState<FilterValues>({
        search: undefined,
        date: { startDate: undefined, endDate: undefined, within: undefined },
        type: undefined,
        priceRange: undefined,
    });
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));
    const toggleAdvancedFilters = () => {
        if (showAdvancedFilters) {
            onClose();
            resetDates();
            setFilter('priceRange', undefined);
        } else openAdvancedFilters();
    };
    React.useEffect(() => setClaimFilters(buildFilters(filters)), [filters]);

    return (
        <Box w="100%">
            <HStack>
                <InputGroup maxW="fit-content">
                    <InputLeftElement pointerEvents="none" children={<SearchIcon color="scheme.icon_dark" transform="scaleX(-1)" />} />
                    <Input
                        value={filters['search']}
                        onChange={e => setFilter('search', e.target.value)}
                        placeholder="Search..."
                        borderColor="rgba(138, 130, 122, 0.5)"
                        focusBorderColor="rgba(138, 130, 122, 0.5)"
                    />
                </InputGroup>
                <DateSelect
                    openAdvancedFilters={openAdvancedFilters}
                    setDate={setDate}
                    resetDates={resetDates}
                    isUsingCustom={!!filters.date.startDate || !!filters.date.endDate}
                />
                <Text as="button" textStyle="underline" onClick={toggleAdvancedFilters}>
                    Advanced Filters
                </Text>
            </HStack>
            <Box h="4" />
            {showAdvancedFilters && <AdvancedFilters setDate={setDate} setFilter={setFilter} filters={filters} />}
        </Box>
    );
};
