import { Divider, Flex, Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BullaClaimInfo } from '../../../data-lib/data-transforms';
import { useQuery } from '../../../hooks/useQuery';
import { useUserData } from '../../../hooks/useUserData';
import { useUserSummary } from '../../../hooks/useUserSummary';
import { useWeb3 } from '../../../hooks/useWeb3';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import BullaView, { BullaBalanceSummary, TabSwitcher } from './bulla-view';

export default () => {
    const history = useHistory();
    const { ethBalance } = useWeb3();
    const { receivables } = useUserData();
    const { payableTotals, receivableTotals, netBalance } = useUserSummary();
    const { tab } = useParams<{ tab?: 'invoices' | 'remittances' }>();

    const setTab = (tab: string) => history.replace({ pathname: `/receivables/${tab}` });
    const tabToClaimType = tab === 'remittances' ? 'Remittance' : 'Invoice';
    const tabFilter = (claim: BullaClaimInfo) => claim.claimType === tabToClaimType;
    const selectedClaim = useQuery('claim');

    return (
        <>
            <SummaryPanel bg="#D9D7D5" px="12" pt="4">
                <Flex>
                    <Heading textStyle="bullaViewHeader">Receivables</Heading>
                    <Spacer />
                    <HStack spacing="8">
                        <BullaBalanceSummary total={payableTotals.total} label="Payable" />
                        <BullaBalanceSummary total={receivableTotals.total} label="Receivable" />
                        <BullaBalanceSummary total={ethBalance || 0} label="Wallet" />
                        <BullaBalanceSummary total={netBalance.balance} label="Net Balance" />
                    </HStack>
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper>
                <Stack pos="relative" px="8" spacing="0">
                    <TabSwitcher
                        tab={tab || 'invoices'}
                        setTab={setTab}
                        options={[
                            { label: 'Invoices', value: 'invoices' },
                            { label: 'Remittances', value: 'remittances' },
                        ]}
                    />
                    <Divider transform="translate(0, -2px)" />
                </Stack>
                <BullaView claims={receivables} filters={[tabFilter]} />
            </MaxWidthWrapper>
        </>
    );
};
