import { BytesLike, Contract, ethers, utils } from 'ethers';
import BullaClaimArtifact from '../../artifacts/contracts/BullaClaim.sol/BullaClaim.json';
import BullaGroupArtifact from '../../artifacts/contracts/BullaGroup.sol/BullaGroup.json';
import BullaManagerArtifact from '../../artifacts/contracts/BullaManager.sol/BullaManager.json';
import { BullaClaim, BullaClaimInterface } from '../../artifacts/typechain/BullaClaim';
import { BullaGroup, BullaGroupInterface } from '../../artifacts/typechain/BullaGroup';
import { BullaManager, BullaManagerInterface } from '../../artifacts/typechain/BullaManager';
import { EthAddress } from './ethereum';

export const intToDate = (int: number) => new Date(int * 1000);
export const dateToInt = (date: Date) => Math.ceil(date.getTime() / 1000); //TODO:Test that this works

export const defaultDate = intToDate(0);

export const bullaManagerInterface = new utils.Interface(BullaManagerArtifact.abi) as BullaManagerInterface;
export const bullaGroupInterface = new utils.Interface(BullaGroupArtifact.abi) as BullaGroupInterface;
export const bullaClaimInterface = new utils.Interface(BullaClaimArtifact.abi) as BullaClaimInterface;

export const getBullaManager = (address: EthAddress) => new Contract(address, BullaManagerArtifact.abi) as BullaManager;

export const getBullaGroup = (address: EthAddress) => new Contract(address, BullaGroupArtifact.abi) as BullaGroup;
export const getBullaClaim = (address: EthAddress) => new Contract(address, BullaClaimArtifact.abi) as BullaClaim;

export const toBytes32 = (stringVal: string) => ethers.utils.formatBytes32String(stringVal);
export const fromBytes32 = (bytesVal: BytesLike) => ethers.utils.parseBytes32String(bytesVal);
//export const toWei = (ether:number) => ethers.utils.parseEther(String(ether));
//export const toEther = (wei:BigNumber) => ethers.utils.formatEther(wei);

export const dateLabel = (date: Date) => date.toISOString().replace(/\D/g, '');
export const addDaysToToday = (days: number) => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + days));
};
