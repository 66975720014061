import { JsonRpcProvider, Log, Provider, Web3Provider } from '@ethersproject/providers';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingScreen } from '../components/layout/page-layout';
import { BullaClaimInfo, BullaInfo, getUserData } from '../data-lib/data-transforms';
import { EthAddress } from '../data-lib/ethereum';
import {
    events,
    EventType,
    getAllHistoricalLogs,
    getTopicStrings,
    mapEventFromLog,
    MappedEventType,
    RecordEventLogs,
    TopicStrings,
} from '../data-lib/historical-logs';
import { networks } from '../data-lib/networks';
import { useWeb3 } from '../hooks/useWeb3';

export type UserData = {
    userAddress: EthAddress;
    receivables: BullaClaimInfo[];
    payables: BullaClaimInfo[];
    bullas: BullaInfo[];
    findClaim: (claimAddress: EthAddress) => BullaClaimInfo | undefined;
};

const initUserData: UserData = {
    userAddress: '',
    receivables: [],
    payables: [],
    bullas: [],
    findClaim: () => undefined,
};

export type AppState = {
    eventLogs: RecordEventLogs;
    userData: UserData;
};

export const initAppState: AppState = {
    eventLogs: {} as RecordEventLogs,
    userData: initUserData,
};

export const AppContext = React.createContext<AppState>(initAppState);

type ProviderState = 'uninitialized' | 'loading' | 'finished' | 'failed';

export const AppProvider = ({ children: app }: { children: React.ReactNode }) => {
    const [state, setState] = useState<ProviderState>('uninitialized');
    const [error, setError] = useState<string | undefined>();
    const [eventLogs, setEventLogs] = useState<RecordEventLogs>({} as RecordEventLogs);
    const [userData, setUserData] = useState(initUserData);
    const { address, rpcProvider, network, provider } = useWeb3();

    const updateEventLogs = (eventType: EventType, newLog: MappedEventType) => {
        setEventLogs(previousLogs => ({
            ...previousLogs,
            [eventType]: { eventType, events: [...previousLogs[eventType].events, newLog] },
        }));
    };

    const buildUserData = (address: EthAddress, eventLogs: RecordEventLogs) => {
        const user = getUserData(address, eventLogs);
        setUserData(user);
    };

    const subscribeToEvents = (provider: Provider, topicStrings: Record<EventType, TopicStrings>) => {
        Object.values(events).forEach(({ eventType, abiInterface }) => {
            provider.on(
                topicStrings[eventType] as string[], // still buggy
                (log: Log) => {
                    const event = mapEventFromLog(abiInterface.parseLog(log), eventType, log.transactionHash);
                    updateEventLogs(eventType, event);
                },
            );
        });
    };

    const getEventLogs = async (
        userAddress: string | undefined,
        rpcProvider: JsonRpcProvider | undefined,
        web3Provider: Web3Provider | undefined,
    ) => {
        if (rpcProvider && web3Provider && userAddress && network) {
            const provider = network === 30 || network === 100 ? rpcProvider : web3Provider;
            const { creatorContract, deployedOnBlock } = networks[network];
            const topicStrings = getTopicStrings(creatorContract, window.ethereum.selectedAddress); //BUG: when this effect reruns after a metamask network switch, the provider will have the correct information, but the userAddress will be stale... will fix in refactor useWeb3

            try {
                setState('loading');
                await getAllHistoricalLogs(provider, topicStrings, deployedOnBlock).then(setEventLogs);
                subscribeToEvents(provider, topicStrings);
                setState('finished');
            } catch (e) {
                console.error(e);
                setState('failed');
            }
        }
    };

    useEffect(() => {
        getEventLogs(address, rpcProvider, provider);
        return () => {
            provider?.removeAllListeners();
            rpcProvider?.removeAllListeners();
        };
    }, [address, rpcProvider, provider]); // when the network changes, the rpcProvider will rebuild, giving us access to the new logs, or when the address needs to change, get the new user logs

    useEffect(() => {
        if (address) buildUserData(address, eventLogs);
    }, [eventLogs]);

    const context = useMemo(() => ({ userData, eventLogs }), [userData, eventLogs]);

    return (
        <AppContext.Provider value={context}>
            <LoadingScreen isLoading={state !== 'finished'} hasFailed={state === 'failed'} errorMessage={error} />
            {state === 'finished' && app}
        </AppContext.Provider>
    );
};

export const useAppState = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) throw new Error('useAppState must me used within the AppState provider');
    return context;
};
