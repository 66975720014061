import { Button, Heading, HStack, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { changeNetwork, networks } from '../../../data-lib/networks';
import { useQuery } from '../../../hooks/useQuery';
import { useWeb3 } from '../../../hooks/useWeb3';

export const ClaimNotFound = ({ handleClose }: { handleClose: () => void }) => {
    const claimNetwork = Number(useQuery('network'));
    const { network: userNetwork } = useWeb3();
    const wrongNetwork = claimNetwork && claimNetwork !== userNetwork ? true : false;
    const handleSwitchNetwork = () => changeNetwork(claimNetwork);
    const claimNetworkName = networks[claimNetwork]?.name;

    return (
        <>
            <ModalHeader>
                <Heading color="icon_dark" textStyle="labelLg">
                    Claim Details
                </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={6}>
                <Stack align="center" justify="center" py="4">
                    {wrongNetwork ? (
                        <>
                            <Text textStyle="faint">You are connected to the wrong network </Text>
                            {claimNetworkName && (
                                <Text as="button" fontWeight="500" textStyle="underline" onClick={handleSwitchNetwork}>
                                    Switch to {claimNetworkName}
                                </Text>
                            )}
                        </>
                    ) : (
                        <Text textStyle="faint">This claim is not associated with your wallet</Text>
                    )}
                </Stack>
            </ModalBody>
            <ModalFooter>
                <HStack spacing="4" w="100%" justify="center">
                    <Spacer />
                    <Button px="8" py="6" fontWeight="500" colorScheme="dark" onClick={handleClose}>
                        Close
                    </Button>
                </HStack>
            </ModalFooter>
        </>
    );
};
