import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Box, Button, chakra, Container, Heading, Image, SlideFade, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TransferMoneyIllustration from 'url:../assets/transfer_money.svg';
import { connectWallet } from '../components/display/wallet-info';
import { PageLayout } from '../components/layout/page-layout';
import { changeNetwork } from '../data-lib/networks';
import { useWeb3 } from '../hooks/useWeb3';

const ConfirmationButton = ({
    onClick,
    validation,
    falseText,
    trueText,
    ...rest
}: {
    onClick: () => void;
    validation: boolean;
    falseText: string;
    trueText: string;
}) => {
    return (
        <Button
            onClick={onClick}
            colorScheme="accent"
            w="48"
            h="12"
            isDisabled={validation}
            rightIcon={validation ? <CheckIcon /> : undefined}
            {...rest}
        >
            {!validation && <Text fontWeight="700">{falseText}</Text>}
            <SlideFade in={validation}>
                <Text display={validation ? 'block' : 'none'} fontWeight="700">
                    {trueText}
                </Text>
            </SlideFade>
        </Button>
    );
};

const StepContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <SlideFade in>
            <Stack align="center" spacing="0" mt="8">
                {children}
            </Stack>
        </SlideFade>
    );
};

export default () => {
    const [userTookRecommendation, setUserTookRecommendation] = React.useState(false);
    const { onboard, initialized, walletReady, network } = useWeb3();
    const history = useHistory();
    const goHome = () => history.push('/');
    const usexDai = () => {
        setUserTookRecommendation(true);
        changeNetwork(100);
    };
    const usingxDai = network === 100;
    const showRecomendation = userTookRecommendation || (walletReady && !usingxDai);
    return (
        <PageLayout isLoading={!initialized}>
            <Container maxW="lg" centerContent my="12">
                <Image w="100%" src={TransferMoneyIllustration} alt="illustration of two people sending eachother digital currency" />
                <Box h="8" />
                <Heading textStyle="dark">Welcome to Bulla Network</Heading>
                <StepContainer>
                    <Text as="b" textStyle="dark">
                        Step 1
                    </Text>
                    <Text>Connect your wallet</Text>
                    <Box h="4" />
                    <ConfirmationButton
                        data-testid='connectWalletButton'
                        onClick={() => connectWallet(onboard)}
                        validation={walletReady}
                        falseText="Connect Wallet"
                        trueText="Wallet Connected"
                    />
                </StepContainer>
                {showRecomendation && (
                    <StepContainer>
                        <Text as="b" textStyle="dark">
                            Step 2
                        </Text>
                        <Text>Connect to the blockchain</Text>
                        <Text textStyle="faint" mb="4" mt="2">
                            We recommend you use the{' '}
                            <chakra.span
                                as="button"
                                color="teal.500"
                                onClick={() => window.open('https://www.xdaichain.com/', '_blank')}
                                _hover={{ textDecoration: 'underline' }}
                            >
                                xDai chain.
                            </chakra.span>
                        </Text>
                        <ConfirmationButton
                            onClick={usexDai}
                            validation={usingxDai}
                            falseText="Connect to xDai chain"
                            trueText="Connected"
                        />
                    </StepContainer>
                )}
                {walletReady && (
                    <StepContainer>
                        <Text as="b" textStyle="dark">
                            Step {showRecomendation ? '3' : '2'}
                        </Text>
                        <Text>Enter App</Text>
                        <Box h="4" />
                        <Button onClick={goHome} colorScheme="accent" w="48" h="12" rightIcon={<ArrowForwardIcon />}>
                            Enter App
                        </Button>
                    </StepContainer>
                )}
                <Box h="8" />
                <Text role="button" textStyle="underline">
                    Help
                </Text>
            </Container>
        </PageLayout>
    );
};
