//Base of this is taken from https://github.com/ChainSafe/web3-context
import { formatEther } from '@ethersproject/units';
import Onboard from 'bnc-onboard';
import { API as OnboardApi, Initialization, Wallet } from 'bnc-onboard/dist/src/interfaces';
import { ethers, providers } from 'ethers';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { networks } from '../data-lib/networks';

export type OnboardConfig = Partial<Omit<Initialization, 'networkId'>>;

type Web3ContextProps = {
    children: React.ReactNode;
    networkIds: number[];
};

type Web3Context = {
    address?: string;
    ethBalance?: number;
    isMobile: boolean;
    initialized: boolean;
    walletReady: boolean;
    network?: number;
    onboard?: OnboardApi;
    provider?: providers.Web3Provider;
    rpcProvider?: providers.JsonRpcProvider;
    wallet?: Wallet;
    resetOnboard(): void;
    signMessage(message: string): Promise<string>;
};

const Web3Context = React.createContext<Web3Context | undefined>(undefined);

const Web3Provider = ({ children, networkIds }: Web3ContextProps) => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const [address, setAddress] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<providers.Web3Provider | undefined>(undefined);
    const [rpcProvider, setRpcProvider] = useState<providers.JsonRpcProvider | undefined>(undefined);
    const [network, setNetwork] = useState<number | undefined>(undefined);
    const [ethBalance, setEthBalance] = useState<number | undefined>(undefined);
    const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
    const [walletReady, setWalletReady] = useState<boolean>(false);
    const [onboard, setOnboard] = useState<OnboardApi | undefined>(undefined);
    const checks = [{ checkName: 'accounts' }, { checkName: 'connect' }, { checkName: 'network' }];

    useEffect(() => {
        const initializeOnboard = async () => {
            const initialChain: string | undefined = window.ethereum?.chainId;
            const networkId = initialChain && !isNaN(+initialChain) ? +initialChain : 1;

            try {
                const onboard = Onboard({
                    networkId, //Default to mainnet
                    walletCheck: checks,
                    walletSelect: { wallets: [{ walletName: 'metamask', preferred: true }, { walletName: 'coinbase' }] },
                    subscriptions: {
                        address: address => {
                            setAddress(address);
                            onboard?.walletCheck().then(setWalletReady);
                        },
                        wallet: wallet => {
                            if (wallet.provider) {
                                setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
                                wallet.name && localStorage.setItem('onboard.selectedWallet', wallet.name);
                            }
                            setWallet(wallet.provider ? wallet : undefined);
                            onboard?.walletCheck().then(setWalletReady);
                        },
                        network: network => {
                            setNetwork(network);
                            wallet?.provider && setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'));
                            if (networkIds.includes(network)) onboard.config({ networkId: network }); // @jfrank-summit
                            const rpcUrl = networks[network] ? networks[network].rpcUrl : '';
                            setRpcProvider(new providers.JsonRpcProvider(rpcUrl));
                            onboard?.walletCheck().then(setWalletReady);
                        },
                        balance: balance => {
                            try {
                                const bal = Number(formatEther(balance));
                                !isNaN(bal) ? setEthBalance(bal) : setEthBalance(0);
                            } catch (e) {
                                setEthBalance(0);
                            }
                        },
                    },
                });

                const savedWallet = localStorage.getItem('onboard.selectedWallet');
                if (savedWallet) await onboard.walletSelect(savedWallet);
                setOnboard(onboard);
                return true;
            } catch (error) {
                console.log('Error initializing onboard');
                console.log(error);
                return false;
            }
        };

        initializeOnboard().then(setInitialized);
    }, []);

    const signMessage = async (message: string) => {
        if (!provider) return Promise.reject('The provider is not yet initialized');

        const data = ethers.utils.toUtf8Bytes(message);
        const signer = provider.getSigner();
        const addr = await signer.getAddress();
        const sig = await provider.send('personal_sign', [ethers.utils.hexlify(data), addr.toLowerCase()]);
        return sig;
    };

    const resetOnboard = () => {
        localStorage.setItem('onboard.selectedWallet', '');
        onboard?.walletReset();
    };

    const onboardState = onboard?.getState();

    return (
        <Web3Context.Provider
            value={{
                address,
                provider,
                rpcProvider,
                network,
                ethBalance,
                wallet,
                walletReady,
                initialized,
                onboard,
                resetOnboard,
                isMobile: !!onboardState?.mobileDevice,
                signMessage,
            }}
        >
            {initialized && children}
        </Web3Context.Provider>
    ); // TODO: do we want the initialized flag to be there?
};

const useWeb3 = () => {
    const context = React.useContext(Web3Context);
    if (context === undefined) throw new Error('useWeb3 must be used within the Web3Provider');
    return context;
};

export { Web3Provider, useWeb3 };
