import React from 'react';
import { createBullaClaim } from '../data-lib/bulla-group';
import { ClaimType } from '../data-lib/data-transforms';
import { EthAddress } from '../data-lib/ethereum';
import { getBullaGroup } from '../data-lib/helpers';
import { networks } from '../data-lib/networks';
import { useUIState } from '../state/ui-state';
import { useWeb3 } from './useWeb3';

type CreateInvoiceParams = {
    bullaId: number;
    claimAmount: number;
    debtor: EthAddress;
    description: string;
    dueBy: Date;
    emailAddress?: string;
    emailMessage?: string;
};

export const useCreateBullaClaim = () => {
    const [creating, setCreating] = React.useState(false);
    const { provider, address, network } = useWeb3();
    const { setPendingTxn } = useUIState();
    const signer = provider?.getSigner();

    const createClaim = async (
        claimType: ClaimType,
        { bullaId, claimAmount, debtor: recipient, description, dueBy }: CreateInvoiceParams,
    ) => {
        if (signer && address) {
            try {
                setCreating(true);
                const [creditor, debtor] = claimType === 'Invoice' ? [address, recipient] : [recipient, address];
                const { receipt } = await createBullaClaim(setPendingTxn, {
                    bullaId,
                    claimAmount,
                    description,
                    creditor,
                    debtor,
                    dueBy,
                    bullaGroup: getBullaGroup(networks[network || 4].bullaBanker),
                    signer,
                });
                setPendingTxn({ pending: false, hash: receipt.transactionHash, receipt });
                return receipt;
            } catch (e) {
                console.error(e);
                setPendingTxn({ pending: false, failed: { reason: e.message, hasFailed: true } });
            } finally {
                setCreating(false);
            }
        }
    };

    return [creating, createClaim] as const;
};
