import { Button, ButtonProps, Text } from '@chakra-ui/react';
import React from 'react';
import { addressEquality, EthAddress } from '../../data-lib/ethereum';
import { useClaimFunction } from '../../hooks/useBullaClaim';
import { useUserData } from '../../hooks/useUserData';
import { useUIState } from '../../state/ui-state';
import { ChakraCompose } from '../../tools/types';

type ActionButtonProps = {
    selectedClaim?: EthAddress;
    onComplete?: () => void;
};

type ActionButtonBaseProps = ChakraCompose &
    ButtonProps & {
        handleClick: () => void;
        isLoading: boolean;
        isDisabled?: boolean;
    };

export const ActionButton = ({ children, handleClick, isLoading, isDisabled, ...props }: ActionButtonBaseProps) => {
    const { pendingTxn } = useUIState();

    return (
        <Button
            isLoading={isLoading}
            w="32"
            h="12"
            {...props}
            isDisabled={pendingTxn.pending || isDisabled || !handleClick}
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

export const PayButton = ({ selectedClaim, onComplete }: ActionButtonProps) => {
    const { findClaim } = useUserData();
    const [doing, { payClaim }] = useClaimFunction();
    const selectedClaimInfo = selectedClaim ? findClaim(selectedClaim) : undefined;
    const isPending = selectedClaimInfo?.claimStatus === 'Pending';
    const handlePay = () => selectedClaimInfo && payClaim(selectedClaimInfo?.claimAddress, selectedClaimInfo.claimAmount).then(onComplete);

    return (
        <ActionButton
            isLoading={doing}
            isDisabled={!selectedClaim || !isPending}
            handleClick={handlePay}
            colorScheme={'accent'}
            data-testid="triggerPayClaim"
        >
            <Text fontWeight="500">Pay</Text>
        </ActionButton>
    );
};

export const CancelButton = ({ selectedClaim, onComplete }: ActionButtonProps) => {
    const { findClaim, userAddress } = useUserData();
    const [doing, { rescindClaim, rejectClaim }] = useClaimFunction();
    const selectedClaimInfo = selectedClaim ? findClaim(selectedClaim) : undefined;
    const { creditor, debtor, claimType } = selectedClaimInfo ? selectedClaimInfo : { creditor: '', debtor: '', claimType: '' };

    const isPending = selectedClaimInfo?.claimStatus === 'Pending';
    const isPayable = addressEquality(userAddress, debtor);
    const isReceivable = addressEquality(userAddress, creditor);
    const isOnlyPayable = isPayable && !isReceivable;
    const isSelfRemittance = isPayable && isReceivable;
    const claimAction = isSelfRemittance ? rescindClaim : isPayable ? rejectClaim : rescindClaim;

    const handleCancel = () =>
        selectedClaimInfo &&
        claimAction(selectedClaimInfo.claimAddress).then(() => {
            if (onComplete) onComplete();
        });

    return selectedClaimInfo ? (
        <ActionButton
            isLoading={doing}
            isDisabled={!isPending}
            handleClick={handleCancel}
            colorScheme={'gray'}
            data-testid="triggerCancelClaim"
        >
            <Text fontWeight="500">{isOnlyPayable && claimType === 'Invoice' ? 'Reject' : 'Cancel Claim'}</Text>
        </ActionButton>
    ) : null;
};
