import { ArrowBackIcon, ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Divider, Flex, Grid, GridItem, HStack, IconButton, Spacer, Text, TextProps } from '@chakra-ui/react';
import React from 'react';
import { BullaClaimInfo, ClaimStatus } from '../../data-lib/data-transforms';
import { addressEquality } from '../../data-lib/ethereum';
import { useOpenClaimDetails } from '../../hooks/useClaimDetailDisclosure';
import { useUserData } from '../../hooks/useUserData';
import { toDateDisplay } from '../../tools/common';
import { ChakraCompose } from '../../tools/types';
import { AddressLabel } from '../base/address-label';
import { getStatusBadge } from '../base/status-badge';
import { Currency } from '../currency/currency';
import { getClaimRelationToUser } from '../modals/claim-details-modal/claim-details';

type Categories = 'All' | Exclude<ClaimStatus, 'Repaying'>;
type PageSelectorProps = {
    pageTotal: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    nextPage: () => void;
    prevPage: () => void;
};
type CategoryProps = ChakraCompose & {
    onClick: () => void;
};

const countCategories = (allClaims: BullaClaimInfo[]): Record<Categories, number> =>
    allClaims.reduce(
        (categoryMap, { claimStatus }): Record<Categories, number> => {
            claimStatus === 'Repaying' ? (categoryMap['Pending'] += 1) : (categoryMap[claimStatus] += 1);
            categoryMap['All']++;
            return categoryMap;
        },
        { All: 0, Pending: 0, Rescinded: 0, Rejected: 0, Paid: 0 },
    );

const HEADERS = ['status', 'claim type', 'received', 'wallet id', 'bulla name', 'description', 'amount'];

const Category = ({ children, onClick, ...overrides }: CategoryProps & TextProps) => (
    <Box as="button" onClick={onClick} px="4" py="2" borderRadius="full" {...overrides}>
        <Text>{children}</Text>
    </Box>
);

const SelectedCategory = ({ children, onClick }: CategoryProps) => (
    <Category onClick={onClick} bg="#DEDCDA" textStyle="hover">
        {children}
    </Category>
);

const Cell = ({ children }: { children?: React.ReactNode }) => (
    <GridItem colSpan={1}>
        <Flex align="center" h="100%" py="2" px="2">
            {children}
        </Flex>
    </GridItem>
);

const CircleButton = ({ isActive, onClick, children }: { isActive?: boolean; onClick: () => void; children: React.ReactNode }) => (
    <Button variant="ghost" borderRadius="full" isActive={isActive} onClick={onClick} maxWidth="2em">
        <Text opacity={isActive ? '1' : '0.5'}>{children}</Text>
    </Button>
);

const PageSelector = ({ pageTotal, currentPage, setCurrentPage, nextPage, prevPage }: PageSelectorProps) => {
    return (
        <>
            {pageTotal > 1 ? (
                <Center>
                    <HStack m="8">
                        <IconButton
                            onClick={() => setCurrentPage(1)}
                            disabled={currentPage === 1}
                            icon={<ArrowLeftIcon />}
                            colorScheme="accent"
                            variant="ghost"
                            aria-label="First page"
                        />
                        <IconButton
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            icon={<ArrowBackIcon />}
                            colorScheme="accent"
                            variant="ghost"
                            aria-label="Previous page"
                        />
                        {pageTotal > 8 ? (
                            <>
                                {[...Array(7)].map((_, i) => {
                                    const isWithinRangeOfFinalPage = currentPage + 7 >= pageTotal;
                                    const pageNumber = isWithinRangeOfFinalPage ? pageTotal - 7 + i : currentPage + i;
                                    return (
                                        <React.Fragment key={i}>
                                            {i === 6 && !isWithinRangeOfFinalPage ? (
                                                <CircleButton onClick={() => setCurrentPage(pageTotal - 1)}>...</CircleButton>
                                            ) : (
                                                <CircleButton
                                                    isActive={pageNumber === currentPage}
                                                    onClick={() => setCurrentPage(pageNumber)}
                                                >
                                                    {pageNumber}
                                                </CircleButton>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                <CircleButton
                                    key={pageTotal}
                                    onClick={() => setCurrentPage(pageTotal)}
                                    isActive={pageTotal === currentPage}
                                >
                                    {pageTotal}
                                </CircleButton>
                            </>
                        ) : (
                            [...Array(pageTotal)].map((_, i) => (
                                <CircleButton isActive={i + 1 === currentPage} onClick={() => setCurrentPage(i + 1)} key={i}>
                                    {i + 1}
                                </CircleButton>
                            ))
                        )}
                        <IconButton
                            onClick={nextPage}
                            disabled={currentPage === pageTotal}
                            icon={<ArrowForwardIcon />}
                            colorScheme="accent"
                            variant="ghost"
                            aria-label="Next page"
                        />
                        <IconButton
                            onClick={() => setCurrentPage(pageTotal)}
                            disabled={currentPage === pageTotal}
                            icon={<ArrowRightIcon />}
                            colorScheme="accent"
                            variant="ghost"
                            aria-label="Last page"
                        />
                    </HStack>
                </Center>
            ) : (
                <Box h="16" />
            )}
        </>
    );
};

export default ({ claims, filters }: { claims: BullaClaimInfo[]; filters: ((claim: BullaClaimInfo) => Boolean)[] }) => {
    const CLAIMSPERPAGE = 6;

    const { userAddress } = useUserData();
    const openClaim = useOpenClaimDetails();

    const [category, setCategory] = React.useState<Categories>('All');
    const [currentPage, setCurrentPage] = React.useState(1);

    const filteredClaims = filters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...claims]);
    const claimsByCategory = category === 'All' ? filteredClaims : filteredClaims.filter(claim => claim.claimStatus === category);

    const pageTotal = Math.ceil(claimsByCategory.length / CLAIMSPERPAGE);
    const visibleClaims = claimsByCategory.slice(currentPage * CLAIMSPERPAGE - CLAIMSPERPAGE, currentPage * CLAIMSPERPAGE);

    const categoryLabelToAmount = countCategories(filteredClaims);
    const handleChangeCategory = (categoryLabel: Categories) => {
        setCategory(categoryLabel);
        setCurrentPage(1);
    };
    const nextPage = () => setCurrentPage(currentPage => currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage => currentPage - 1);
    const emptyMessage = () => {
        if (category === 'All') {
            // if (address) return 'No payables yet... whew.';
            // if (address && tab === 'receivables') return "No receivables yet... let's get to work!";
            return '';
        }
    };

    return (
        <>
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                <Box p="6" bg="scheme.accent_light">
                    <HStack>
                        {Object.entries(categoryLabelToAmount).map(([categoryLabel, amount]) =>
                            categoryLabel === category ? (
                                <SelectedCategory onClick={() => handleChangeCategory(categoryLabel)} key={categoryLabel}>
                                    {categoryLabel} ({amount})
                                </SelectedCategory>
                            ) : (
                                <Category onClick={() => handleChangeCategory(categoryLabel as Categories)} key={categoryLabel}>
                                    {categoryLabel} ({amount})
                                </Category>
                            ),
                        )}
                    </HStack>
                </Box>
                <Grid px="4" templateRows={`repeat(${CLAIMSPERPAGE + 1}, 4em)`}>
                    {HEADERS.map(header => (
                        <Cell key={header}>
                            <Text textStyle="columnName">{header}</Text>
                        </Cell>
                    ))}
                    {!claimsByCategory.length ? (
                        <GridItem colSpan={HEADERS.length} rowSpan={CLAIMSPERPAGE} align="center" alignSelf="center">
                            {emptyMessage()}
                        </GridItem>
                    ) : (
                        visibleClaims.map(claim => {
                            const {
                                claimStatus,
                                claimType,
                                created,
                                debtor,
                                creditor,
                                bullaDescription,
                                description,
                                claimAmount,
                                claimAddress,
                            } = claim;
                            const addressToShow = addressEquality(userAddress, debtor) ? creditor : debtor;
                            const { isPayable } = getClaimRelationToUser(userAddress, claim);

                            return (
                                <GridItem pos="relative" colSpan={HEADERS.length} key={claimAddress}>
                                    <Grid templateColumns={`repeat(${HEADERS.length}, 1fr)`}>
                                        <Cell>{getStatusBadge(claimStatus, isPayable)}</Cell>
                                        <Cell>{claimType}</Cell>
                                        <Cell>
                                            <Text textStyle="cell">{toDateDisplay(created)}</Text>
                                        </Cell>
                                        <Cell>
                                            <AddressLabel ethAddress={addressToShow} />
                                        </Cell>
                                        <Cell>
                                            <Text textStyle="cell">{bullaDescription}</Text>
                                        </Cell>
                                        <Cell>
                                            <Text textStyle="cell">{description}</Text>
                                        </Cell>
                                        <Cell>
                                            <Box textStyle="cell">
                                                <Currency value={claimAmount} />
                                            </Box>
                                            <Spacer />
                                            <IconButton
                                                variant="ghost"
                                                borderRadius="full"
                                                aria-label="expand"
                                                onClick={() => openClaim(claimAddress)}
                                                icon={<ChevronRightIcon />}
                                                data-testid={`open-${description}`}
                                            />
                                        </Cell>
                                    </Grid>
                                    <Divider pos="absolute" bottom="0" />
                                </GridItem>
                            );
                        })
                    )}
                </Grid>
                <PageSelector
                    pageTotal={pageTotal}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    nextPage={nextPage}
                    prevPage={prevPage}
                />
            </Box>
            <Box m="8" />
        </>
    );
};
