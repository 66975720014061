import { ContractReceipt } from 'ethers';
import React from 'react';
import { createBulla } from '../data-lib/bulla-group';
import { getBullaGroup } from '../data-lib/helpers';
import { networks } from '../data-lib/networks';
import { useUIState } from '../state/ui-state';
import { useWeb3 } from './useWeb3';

type CreateBullaParams = {
    description: string;
    ownerFunding?: number;
};

export const useCreateBulla = () => {
    const [creating, setCreating] = React.useState(false);
    const { setPendingTxn } = useUIState();
    const { provider, network = 4 } = useWeb3();
    const signer = provider?.getSigner();
    const bullaGroupAddress = networks[network] ? networks[network].bullaBanker : '';

    const create = async ({ description, ownerFunding = 0 }: CreateBullaParams): Promise<ContractReceipt | undefined> => {
        if (signer) {
            try {
                setCreating(true);
                const { receipt } = await createBulla(setPendingTxn, {
                    description,
                    ownerFunding,
                    bullaGroup: getBullaGroup(bullaGroupAddress),
                    signer,
                });
                setPendingTxn({ pending: false, hash: receipt.transactionHash, receipt });
                return receipt;
            } catch (e) {
                console.error(e);
                setPendingTxn({ pending: false, failed: { reason: e.message, hasFailed: true } });
            } finally {
                setCreating(false);
            }
        }
    };

    return [creating, create] as const;
};
