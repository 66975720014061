declare global {
    interface Window {
        provider: any;
        ethereum: any;
    }
}
const INFURA_API_KEY = process.env.INFURA_API_KEY;
export const SUPPORTED_NETWORKS = [1, 4, 100, 30, 31, 44787];

type TokenAddress = {
    creatorContract: string;
    bullaBanker: string;
};
export type CurrencyType = 'ETH' | 'XDAI' | 'RBTC' | 'CELO';

export type Network = {
    name: string;
    chainId: number;
    deployedOnBlock?: number;
    chain: string;
    label: string;
    rpcUrl: string;
    blockExplorer: string;
    nativeCurrency: { name: string; symbol: string; decimals: number };
    infoURL: string;
    currency: CurrencyType;
    explorers: [
        {
            name: string;
            url: string;
            standard: string;
        },
    ];
} & TokenAddress;

export const networks: Record<number, Network> = {
    1: {
        rpcUrl: `https://mainnet.infura.io/v3/8fb974170b1743288e9e6fac3bed68a0`, //${INFURA_API_KEY}`,
        blockExplorer: 'https:etherscan.io/',
        creatorContract: '0x3518b1740cF1E7722172662373aB4Da069CCE256',
        bullaBanker: '0x8D4bdDdd7F8c34eC64458B13805B5819Cb95c305',
        currency: 'ETH',
        name: 'Ethereum Mainnet',
        label: 'mainnet',
        chain: 'ETH',
        nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
        infoURL: 'https://ethereum.org',
        chainId: 1,
        explorers: [{ name: 'etherscan', url: 'https://etherscan.io', standard: 'EIP3091' }],
    },
    4: {
        name: 'Ethereum Testnet Rinkeby',
        label: 'rinkeby',
        chain: 'ETH',
        chainId: 4,
        deployedOnBlock: 8661296,
        rpcUrl: `https://rinkeby.infura.io/v3/8fb974170b1743288e9e6fac3bed68a0`, //${INFURA_API_KEY}`,
        nativeCurrency: { name: 'Rinkeby Ether', symbol: 'RIN', decimals: 18 },
        blockExplorer: 'https://rinkeby.etherscan.io/',
        infoURL: 'https://www.rinkeby.io',
        creatorContract: '0x51Ca5CF9Ac1407B52143D19D253F3F75f196D86D',
        bullaBanker: '0x386DEEf9489ecABa81Ce0b7a182a3ac470Fd2077',
        currency: 'ETH',
        explorers: [{ name: 'etherscan-rinkeby', url: 'https://rinkeby.etherscan.io', standard: 'EIP3091' }],
    },
    30: {
        name: 'RSK Mainnet',
        label: 'rsk-mainnet',
        chain: 'RSK',
        chainId: 30,
        nativeCurrency: { name: 'RSK Mainnet Ether', symbol: 'RBTC', decimals: 18 },
        infoURL: 'https://rsk.co',
        rpcUrl: 'https://rsk.getblock.io/mainnet/?api_key=8faa4b0c-365c-4c52-aca5-bebb1f5cbbeb', //'https://public-node.rsk.co/',
        blockExplorer: 'https://explorer.rsk.co',
        creatorContract: '0xacF5671dbA5004a8B8eC09eD9cD0215ae6E858b4',
        bullaBanker: '0x7a94E4273b64ff98803D4e23BDd69AF6650Fe79C',
        currency: 'RBTC',
        explorers: [{ name: 'rsk-mainnet', url: 'https://explorer.rsk.co', standard: 'EIP3091' }],
    },
    100: {
        name: 'xDAI Chain',
        label: 'xDai-mainnet',
        chain: 'XDAI',
        chainId: 100,
        deployedOnBlock: 16342424,
        rpcUrl: 'https://stake.getblock.io/mainnet/?api_key=8faa4b0c-365c-4c52-aca5-bebb1f5cbbeb',
        blockExplorer: 'https://blockscout.com/xdai/mainnet',
        nativeCurrency: { name: 'xDAI', symbol: 'xDAI', decimals: 18 },
        infoURL: 'https://forum.poa.network/c/xdai-chain',
        creatorContract: '0x316338c3563d313E71Fff2484BEa16e541592d7e',
        bullaBanker: '0xa210b9933Ce79462e8E423202eE55CFE0e1FA89F',
        currency: 'XDAI',
        explorers: [{ name: 'xdai-mainnet', url: 'https://blockscout.com/xdai/mainnet', standard: 'EIP3091' }],
    },
    44787: {
        name: 'Celo Alfajores Testnet',
        label: 'celo-alfajores',
        chain: 'CELO',
        chainId: 44787,
        deployedOnBlock: undefined,
        rpcUrl: 'https://alfajores-forno.celo-testnet.org',
        blockExplorer: 'https://alfajores-blockscout.celo-testnet.org',
        nativeCurrency: {
            name: 'CELO',
            symbol: 'CELO',
            decimals: 18,
        },
        infoURL: 'https://docs.celo.org/',
        creatorContract: '0x1b4DB52FD952F70d3D28bfbd406dB71940eD8cA9',
        bullaBanker: '0xBAb8C75A12Daee3CaE7e5c720C36Efb217932636',
        currency: 'CELO',
        explorers: [{ name: 'celo-testnet', url: 'https://alfajores-blockscout.celo-testnet.org', standard: 'EIP3091' }],
    },
    // 31: {
    //     name: 'RSK Testnet',
    //     chain: 'RSK',
    //     label: 'rsk-testnet',
    //     chainId: 31,
    //     rpcUrl: 'http://104.42.208.129:4444/',
    //     infoURL: 'https://rsk.co',
    //     nativeCurrency: {
    //         name: 'RSK Testnet Ether',
    //         symbol: 'tRBTC',
    //         decimals: 18,
    //     },
    //     blockExplorer: 'https://explorer.testnet.rsk.co',
    //     creatorContract: '0xe486f7D0f95d71f5b533A711bcEB721be4dEC796',
    //     bullaBanker: '0xc08C00ae3938C1434e766c9Ee8D29f17D4a0Fe29',
    //     currency: 'RBTC',
    //     explorers: [{ name: 'rsk-mainnet', url: 'https://explorer.testnet.rsk.co/', standard: 'EIP3091' }],
    // },
    // 3: {
    //     name: 'ropsten',
    //     chainId: 3,
    //     rpcUrl: `https://ropsten.infura.io/v3/8fb974170b1743288e9e6fac3bed68a0`, //${INFURA_API_KEY}`,
    //     blockExplorer: 'https://ropsten.etherscan.io/',
    //     creatorContract: '',
    //     bullaBanker: '',
    //     currency: 'ETH',
    // },
    // 79377087078960: {
    //     name: 'arbitrum-testnet',
    //     chainId: 79377087078960,
    //     rpcUrl: 'https://kovan3.arbitrum.io/rpc',
    //     blockExplorer: '',
    //     creatorContract: '',
    //     bullaBanker: '',
    //     currency: 'ETH',
    // },
};

export const transactionUri = (txHash: string, network: number) => {
    return `${networks[network].blockExplorer}tx/${txHash}`;
};

export const changeNetwork = async (network: number) => {
    const toHex = (num: number) => '0x' + num.toString(16);
    const userAddress = window.ethereum.selectedAddress;
    const chain = networks[network];
    const chainInfo = {
        chainId: toHex(chain.chainId),
        chainName: chain.name,
        nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol,
            decimals: chain.nativeCurrency.decimals,
        },
        rpcUrls: [chain.rpcUrl],
        blockExplorerUrls: [
            chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url ? chain.explorers[0].url : chain.blockExplorer,
        ],
    };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainInfo.chainId }, userAddress],
        });
    } catch (e) {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainInfo, userAddress],
        });
    }
};
