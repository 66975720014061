import { ChakraProvider } from '@chakra-ui/react';
import dotenv from 'dotenv';
import React from 'react';
import { render } from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { App } from './app';
import { SUPPORTED_NETWORKS } from './data-lib/networks';
import { Web3Provider } from './hooks/useWeb3';
import Onboard from './pages/onboard';
import theme from './theme';
dotenv.config();

render(
    <HashRouter>
        <ChakraProvider theme={theme}>
            <Web3Provider networkIds={SUPPORTED_NETWORKS}>
                <Switch>
                    <Route path="/onboard" component={Onboard} />
                    <Route path="/" component={App} />
                </Switch>
            </Web3Provider>
        </ChakraProvider>
    </HashRouter>,
    document.getElementById('app'),
);
