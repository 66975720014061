import { ContractReceipt } from 'ethers';
import { UserData } from '../state/app-state';
import { addressEquality, EthAddress } from './ethereum';
import {
    ActionType,
    ClaimActionEvent,
    GroupType,
    mapToNewBullaClaimEvent,
    MultihashAddedEvent,
    NewBullaClaimEvent,
    NewBullaEvent,
    NewBullaGroupEvent,
} from './event-mapping';
import {
    getClaimActionEvents,
    getClaimMultihashEvents,
    getNewBullaClaimEvents,
    getNewBullaEvents,
    getNewBullaGroupEvents,
    RecordEventLogs,
} from './historical-logs';
import { getMultihashFromBytes32 } from './mutlihash';

type BullaBaseInfo = {
    description: string;
    created: Date;
};

export type ClaimStatus = 'Pending' | 'Repaying' | 'Paid' | 'Rejected' | 'Rescinded';

export type ClaimType = 'Invoice' | 'Remittance';

export type BullaClaimInfo = BullaBaseInfo & {
    claimAddress: EthAddress;
    ipfsHash: string;
    bullaId: number;
    bullaDescription: string;
    groupAddress: EthAddress;
    claimOwner: EthAddress;
    creditor: EthAddress;
    debtor: EthAddress;
    claimAmount: number;
    paidAmount: number;
    dueBy: Date;
    claimType: ClaimType;
    claimStatus: ClaimStatus;
    claimActions: ClaimActionEvent[];
};
export const sortByClaimStatus = (claims: BullaClaimInfo[]) => {
    const sortOrder = (status: ClaimStatus) => {
        switch (status) {
            case 'Pending':
                return 0;
            case 'Repaying':
                return 1;
            case 'Paid' || 'Rejected' || 'Rescinded':
                return 2;
            default:
                return 5;
        }
    };
    return claims.sort((a, b) => sortOrder(a.claimStatus) - sortOrder(b.claimStatus));
};
export type BullaInfo = BullaBaseInfo & {
    bullaId: number;
    bullaOwner: EthAddress;
    bullaClaims: BullaClaimInfo[];
};

type BullaGroupInfo = BullaBaseInfo & {
    managerAddress: EthAddress;
    groupAddress: EthAddress;
    groupOwner: EthAddress;
    groupType: GroupType;
    bullas: BullaInfo[];
};

type ClaimInfoParams = {
    newClaimEvent: NewBullaClaimEvent;
    claimActions: ClaimActionEvent[];
    ipfsHash: string;
    bullaDescription: string;
};
const getStatus = (claimActions: ClaimActionEvent[], claimAmount: number): ClaimStatus => {
    const payments = claimActions.reduce((acc, action) => action.paymentAmount + acc, 0);
    if (claimActions.length == 0) return 'Pending';
    else if (claimActions.some(a => a.actionType == ActionType.Reject)) return 'Rejected';
    else if (claimActions.some(a => a.actionType == ActionType.Rescind)) return 'Rescinded';
    else if (payments == claimAmount) return 'Paid';
    else return 'Repaying';
};
const mapToIpfsHash = ({ ipfsHash }: MultihashAddedEvent) =>
    getMultihashFromBytes32({
        digest: ipfsHash.hash,
        hashFunction: ipfsHash.hashFunction,
        size: ipfsHash.size,
    }) ?? '';
const mapToBullaClaimInfo = ({ newClaimEvent, claimActions, bullaDescription, ipfsHash }: ClaimInfoParams): BullaClaimInfo => {
    const claimStatus = getStatus(claimActions, newClaimEvent.claimAmount);
    const claimType = newClaimEvent.owner === newClaimEvent.creditor ? 'Invoice' : 'Remittance';
    const paidAmount = claimActions.reduce((acc, action) => action.paymentAmount + acc, 0);
    return {
        claimAddress: newClaimEvent.bullaClaim,
        ipfsHash: ipfsHash,
        bullaId: newClaimEvent.bullaId,
        bullaDescription: bullaDescription,
        groupAddress: newClaimEvent.bullaGroup,
        claimOwner: newClaimEvent.owner,
        creditor: newClaimEvent.creditor,
        debtor: newClaimEvent.debtor,
        claimAmount: newClaimEvent.claimAmount,
        paidAmount: paidAmount,
        claimType: claimType,
        claimStatus: claimStatus,
        claimActions: claimActions,
        dueBy: newClaimEvent.dueBy,
        description: newClaimEvent.description,
        created: newClaimEvent.blocktime,
    };
};

type BullaInfoParams = {
    newBullaEvent: NewBullaEvent;
    bullaClaims: BullaClaimInfo[];
};
const mapToBullaInfo = ({ newBullaEvent, bullaClaims }: BullaInfoParams): BullaInfo => {
    return {
        bullaId: newBullaEvent.bullaId,
        bullaOwner: newBullaEvent.owner,
        description: newBullaEvent.description,
        created: newBullaEvent.blocktime,
        bullaClaims: bullaClaims,
    };
};

type BullaGroupInfoParams = {
    newBullaGroupEvent: NewBullaGroupEvent;
    bullas: BullaInfo[];
};
const mapToBullaGroupInfo = ({ newBullaGroupEvent, bullas }: BullaGroupInfoParams): BullaGroupInfo => {
    return {
        managerAddress: newBullaGroupEvent.bullaManager,
        groupAddress: newBullaGroupEvent.bullaGroup,
        groupOwner: newBullaGroupEvent.owner,
        groupType: newBullaGroupEvent.groupType,
        description: newBullaGroupEvent.description,
        created: newBullaGroupEvent.blocktime,
        bullas: bullas,
    };
};

export const getBullaClaimInfo = (claimAddress: EthAddress, eventLogs: RecordEventLogs) => {
    const claimEvent = getNewBullaClaimEvents(eventLogs).find(evt => addressEquality(claimAddress, evt.bullaClaim));
    const bullaDescription = getNewBullaEvents(eventLogs).find(evt => evt?.bullaId === claimEvent?.bullaId)?.description || ''; // (claimEvent && getBullaInfo(claimEvent?.bullaId, claimEvent?.bullaGroup, eventLogs)?.description) || '';
    const claimActions = getClaimActionEvents(eventLogs).filter(evt => addressEquality(claimAddress, evt.bullaClaim));
    const [currentMultihash] = getClaimMultihashEvents(eventLogs)
        .filter(evt => addressEquality(claimAddress, evt.bullaClaim))
        .sort((a, b) => b.blocktime.getTime() - a.blocktime.getTime());
    const ipfsHash = currentMultihash ? mapToIpfsHash(currentMultihash) : '';
    return claimEvent && mapToBullaClaimInfo({ newClaimEvent: claimEvent, claimActions, bullaDescription, ipfsHash });
};

export const getBullaInfo = (bullaId: number, groupAddress: EthAddress, eventLogs: RecordEventLogs): BullaInfo | undefined => {
    const bullaEvent = getNewBullaEvents(eventLogs).find(evt => addressEquality(groupAddress, evt.bullaGroup) && evt.bullaId == bullaId);
    const claims = getNewBullaClaimEvents(eventLogs)
        .filter(evt => addressEquality(groupAddress, evt.bullaGroup) && evt.bullaId == bullaId)
        .map(evt => getBullaClaimInfo(evt.bullaClaim, eventLogs))
        .filter((claim): claim is BullaClaimInfo => !!claim)
        .sort((a, b) => b.created.getTime() - a.created.getTime());
    return bullaEvent && mapToBullaInfo({ newBullaEvent: bullaEvent, bullaClaims: sortByClaimStatus(claims) });
};
export const getAllBullas = (eventLogs: RecordEventLogs) => {
    return getNewBullaEvents(eventLogs)
        .map(evt => getBullaInfo(evt.bullaId, evt.bullaGroup, eventLogs))
        .filter((bulla): bulla is BullaInfo => !!bulla);
};

export const getBullaGroupInfo = (groupAddress: EthAddress, eventLogs: RecordEventLogs) => {
    const bullas = getNewBullaEvents(eventLogs)
        .filter(evt => addressEquality(groupAddress, evt.bullaGroup))
        .map(evt => getBullaInfo(evt.bullaId, groupAddress, eventLogs))
        .filter((bulla): bulla is BullaInfo => !!bulla);
    const bullaGroupEvent = getNewBullaGroupEvents(eventLogs).find(evt => addressEquality(evt.bullaGroup, groupAddress));
    return (
        bullaGroupEvent &&
        mapToBullaGroupInfo({
            newBullaGroupEvent: bullaGroupEvent,
            bullas: bullas,
        })
    );
};

export const getUserData = (userAddress: EthAddress, eventLogs: RecordEventLogs): UserData => {
    const allUserClaims = getNewBullaClaimEvents(eventLogs)
        .filter(evt => addressEquality(userAddress, evt.creditor) || addressEquality(userAddress, evt.debtor))
        .map(evt => getBullaClaimInfo(evt.bullaClaim, eventLogs))
        .filter((claim): claim is BullaClaimInfo => !!claim)
        .sort((a, b) => b.created.getTime() - a.created.getTime());

    const bullas = getNewBullaEvents(eventLogs)
        .filter(evt => addressEquality(userAddress, evt.owner))
        .map(evt => getBullaInfo(evt.bullaId, evt.bullaGroup, eventLogs))
        .filter((bulla): bulla is BullaInfo => !!bulla);
    const findClaim = (claimAddress: EthAddress) => allUserClaims.find(claim => addressEquality(claimAddress, claim.claimAddress));

    return {
        userAddress: userAddress,
        receivables: getReceivables(userAddress, allUserClaims),
        payables: getPayables(userAddress, allUserClaims),
        bullas: bullas,
        findClaim: findClaim,
    };
};

export const mapTxnReceiptToClaimEvent = (receipt: ContractReceipt): NewBullaClaimEvent | undefined => {
    const eventArgs = receipt?.events?.[0]?.args;
    return mapToNewBullaClaimEvent(eventArgs);
};

export const getReceivables = (userAddress: EthAddress, claims: BullaClaimInfo[]) =>
    claims.filter(claim => addressEquality(userAddress, claim.creditor));
export const getPayables = (userAddress: EthAddress, claims: BullaClaimInfo[]) =>
    claims.filter(claim => addressEquality(userAddress, claim.debtor));
