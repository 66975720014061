import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { API } from 'bnc-onboard/dist/src/interfaces';
import { useSelect } from 'downshift';
import React from 'react';
import { BiWallet } from 'react-icons/bi';
import { changeNetwork, Network, networks } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import { AddressLabel } from '../base/address-label';

export const connectWallet = async (onboard?: API) => {
    //TODO clean up and make this a hook, or a func in tools/
    console.log('resetting onboard', onboard?.getState());
    onboard?.walletReset;
    await onboard?.walletSelect();
    await onboard?.walletCheck();
    console.log('wallet selected', onboard?.getState());
};

const setNetwork = (network: Network) => changeNetwork(network.chainId);

export const WalletSelector = () => {
    const { network } = useWeb3();
    const chains = Object.values(networks);
    const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
        items: chains,
        onSelectedItemChange: ({ selectedItem: network }) => network && setNetwork(network),
        initialSelectedItem: chains.find(chain => chain.chainId === network),
    });

    return (
        <Box>
            <HStack align="center" as="button" {...getToggleButtonProps()}>
                <HStack>
                    <Heading size="sm">Network</Heading>
                    {!isOpen ? <ChevronDownIcon h="6" w="5" /> : <ChevronUpIcon h="6" w="5" />}
                </HStack>
                <Text ml={2}>{network && networks?.[network]?.label ? networks?.[network]?.label : 'unknown network'}</Text>
            </HStack>
            <Stack spacing="0" zIndex="1000" borderRadius="xl" boxShadow="2xl" py="4" pos="absolute" bg="walletInfoBg" {...getMenuProps()}>
                {isOpen &&
                    chains?.map((chain, index) => (
                        <Box
                            role="button"
                            as="button"
                            m="0"
                            py="2"
                            px="4"
                            bg={highlightedIndex === index ? '#3C3937' : 'inherit'}
                            textStyle={highlightedIndex === index ? 'hover' : 'inherit'}
                            key={chain.chainId.toString()}
                            {...getItemProps({ item: chain, index })}
                        >
                            {chain.name}
                        </Box>
                    ))}
            </Stack>
        </Box>
    );
};
export const WalletInfo = () => {
    const { onboard, address, wallet } = useWeb3();
    const walletIcon = <Icon as={BiWallet} boxSize={4} />;
    return (
        <HStack bg="walletInfoBg" borderRadius="2em" px="6" py="1">
            {address && (
                <>
                    <Heading size="sm" textStyle="noWrap">
                        {wallet?.name} Wallet:
                    </Heading>
                    <Box color="scheme.accent_dark">
                        <AddressLabel ethAddress={address} />
                    </Box>
                    <Box w="4" />
                    <WalletSelector />
                </>
            )}

            <Button
                colorScheme="ghost"
                rightIcon={walletIcon}
                aria-label="change wallet"
                onClick={() => connectWallet(onboard)}
                _hover={{ bg: 'rgba(225, 225, 225, .1)' }}
                borderRadius="2em"
                minW="4"
                minH="4"
            >
                {!address && (
                    <Text size="xs" mx="1" textStyle="faint">
                        Connect Your Wallet
                    </Text>
                )}
            </Button>
        </HStack>
    );
};
