import { useHistory, useLocation } from 'react-router-dom';
import { EthAddress } from '../data-lib/ethereum';

export const useOpenClaimDetails = () => {
    const history = useHistory();
    const { search } = useLocation();
    return (claimAddress: EthAddress) => {
        const params = new URLSearchParams(search);
        params.set('claim', claimAddress);
        history.replace({ search: `?${params.toString()}` });
    };
};

export const useCloseClaim = () => {
    const history = useHistory();
    const { search } = useLocation();
    return () => {
        const params = new URLSearchParams(search);
        params.delete('claim');
        params.delete('network');
        history.replace({ search: `?${params.toString()}` });
    };
};
