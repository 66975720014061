import { StackProps } from '@chakra-ui/react';
import React from 'react';
import { networks } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import { DEFAULT_NETWORK } from '../../tools/constants';
import { Ether } from './ether';
import { RBTC } from './rbtc';
import { XDai } from './xdai';
import { Celo } from './celo';

type CurrencyProps = StackProps & { value: number };

export const Currency = ({ value, ...props }: CurrencyProps) => {
    const { network = DEFAULT_NETWORK } = useWeb3();
    const currency = networks[network] ? networks[network].currency : 'ETH';

    switch (currency) {
        case 'ETH':
            return <Ether ether={value} {...props} />;
        case 'XDAI':
            return <XDai xDaiValue={value} {...props} />;
        case 'RBTC':
            return <RBTC rbtc={value} {...props} />;
        case 'CELO':
            return <Celo celo={value} {...props} />;
        default:
            return <></>;
    }
};

export const CurrencyIcon = () => {
    const { network = DEFAULT_NETWORK } = useWeb3();
    const currency = networks[network] ? networks[network].currency : 'ETH';

    switch (currency) {
        case 'ETH':
            return <Ether />;
        case 'XDAI':
            return <XDai />;
        case 'RBTC':
            return <RBTC />;
        default:
            return <></>;
    }
};
