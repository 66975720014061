import { ClaimType } from '../data-lib/data-transforms';
import { EthAddress } from '../data-lib/ethereum';
import { networks } from '../data-lib/networks';

const postEmailUrl =
    'https://prod-05.northcentralus.logic.azure.com:443/workflows/17457ba109684c6892ec92e040c3a4ae/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=iQfdHY4eufhI6WzTmScf4outDnXP18V-2dg8xXxuz9g';

const sendEmail = async (body: string) => {
    let res = await fetch(postEmailUrl, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body,
    });
    console.log(res);
    return res.status;
};

export default () => {
    const sendInvoiceNotification = ({
        emailAddress,
        claimType,
        creditor,
        debtor,
        claimAmount,
        claimAddress,
        network,
        description,
        emailMessage,
    }: {
        emailAddress: string;
        claimType: ClaimType;
        creditor: EthAddress;
        debtor: EthAddress;
        claimAmount: number;
        claimAddress: EthAddress;
        network: number;
        description: string;
        emailMessage: string;
    }) => {
        const link = `<a href="https://banker.bulla.network/#/payables/invoices?claim=${claimAddress}">here</a>`;
        const { currency, name: networkName } = networks[network];

        const webhookPayload = JSON.stringify({
            emailAddress,
            claimType,
            creditor,
            debtor,
            claimAmount: claimAmount.toString(),
            currency,
            networkName,
            description,
            emailMessage,
            link,
        });
        console.log(webhookPayload);
        sendEmail(webhookPayload);
    };

    return {
        sendInvoiceNotification,
    };
};
