import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { useSelect } from 'downshift';
import React from 'react';
import { BullaClaimInfo, BullaInfo } from '../../../data-lib/data-transforms';
import { Currency } from '../../currency/currency';
import BullaClaimTable from '../bulla-claim-table';
import BullaFilter from './bulla-filter';
export type TableFilter = (claim: BullaClaimInfo) => boolean;

export const BullaBalanceSummary = ({ label, total }: { label: string; total: number }) => {
    return (
        <HStack>
            <Text>{label}</Text>
            <Currency value={total} fontWeight="bold" />
        </HStack>
    );
};

export const BullaSelector = ({
    bullas,
    onSelect,
    initialBulla,
}: {
    bullas: BullaInfo[];
    onSelect: (bulla: BullaInfo) => void;
    initialBulla?: BullaInfo;
}) => {
    const {
        isOpen,
        selectedItem: currentBulla,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: bullas,
        onSelectedItemChange: ({ selectedItem }) => selectedItem && onSelect(selectedItem),
        initialSelectedItem: initialBulla,
    });

    return (
        <Box>
            <Flex align="center" {...getToggleButtonProps()} as="button">
                <Heading textStyle="bullaViewHeader">{currentBulla?.description ?? 'Select bulla...'}</Heading>
                <Box w="2" />
                {!isOpen ? <ChevronDownIcon h="8" w="7" /> : <ChevronUpIcon h="8" w="7" />}
            </Flex>
            <Stack spacing="0" zIndex="1000" borderRadius="xl" boxShadow="2xl" py="4" pos="absolute" bg="#D9D7D5" {...getMenuProps()}>
                {isOpen &&
                    bullas?.map((bulla, index) => (
                        <Box
                            m="0"
                            py="2"
                            px="4"
                            bg={highlightedIndex === index ? 'scheme.accent_light' : 'inherit'}
                            textStyle={highlightedIndex === index ? 'hover' : 'inherit'}
                            key={bulla.bullaId.toString()}
                            {...getItemProps({ item: bulla, index })}
                        >
                            {bulla.description}
                        </Box>
                    ))}
            </Stack>
        </Box>
    );
};

export type TabOptions = { label: string; value: string };
export const TabSwitcher = ({
    tab,
    setTab,
    options,
}: {
    tab: string;
    setTab: (tab: string) => void;
    options: [TabOptions, TabOptions];
}) => {
    const isActive = (type: string) => type === tab;
    const [first, second] = options;
    return (
        <HStack zIndex="10" mt="4">
            <Box
                px="4"
                py="2"
                as="button"
                role="button"
                onClick={() => setTab(first.value)}
                data-testid={`${first.value}Tab`}
                borderBottomWidth="2px"
                borderBottomColor={isActive(first.value) ? 'highlight' : 'none'}
                color={isActive(first.value) ? 'highlight' : 'inherit'}
            >
                <Text fontSize="1.1em">{first.label}</Text>
            </Box>
            <Box
                px="4"
                py="2"
                as="button"
                role="button"
                onClick={() => setTab(second.value)}
                data-testid={`${second.value}Tab`}
                borderBottomWidth="2px"
                borderBottomColor={isActive(second.value) ? 'highlight' : 'none'}
                color={isActive(second.value) ? 'highlight' : 'inherit'}
            >
                <Text fontSize="1.1em">{second.label}</Text>
            </Box>
        </HStack>
    );
};

export default ({
    claims,
    filters: defaultFilters = [],
    actionButton,
}: {
    claims: BullaClaimInfo[];
    filters?: TableFilter[];
    actionButton?: React.ReactNode;
}) => {
    const [claimFilters, setClaimFilters] = React.useState<TableFilter[] | []>([]);

    return (
        <>
            <Box p="8">
                <HStack align="flex-start">
                    <BullaFilter setClaimFilters={setClaimFilters} />
                    <Spacer />
                    {actionButton && <Box h="100%">{actionButton}</Box>}
                </HStack>
                <Box m="4" />
                <BullaClaimTable claims={claims} filters={[...defaultFilters, ...claimFilters]} />
            </Box>
        </>
    );
};
