import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { HStack, Link, StackProps, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { validAddress } from '../../data-lib/ethereum';
import { transactionUri } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import CopyElement from './copy-element';

const shortAddress = (ethAddress: string) => (validAddress(ethAddress) ? `${ethAddress.slice(0, 5)}...${ethAddress.slice(-4)}` : '');

type AddressLabelProps = StackProps & {
    ethAddress: string;
    iconOnly?: Boolean;
    light?: Boolean;
};

export const AddressLabel = ({ ethAddress, iconOnly, light, ...props }: AddressLabelProps) => {
    return (
        <HStack {...props} spacing="2px">
            {!iconOnly && (
                <Tooltip label={ethAddress} fontSize="xs">
                    <Text color={light ? 'white' : 'accent'}>{shortAddress(ethAddress)}</Text>
                </Tooltip>
            )}
            <CopyElement valueToCopy={ethAddress} light={!!light}>
                <CopyIcon mt="-2px" />
            </CopyElement>
        </HStack>
    );
};

type TXHashLabelProps = StackProps & {
    txHash: string;
};

export const TXHashLabel = ({ txHash, ...props }: TXHashLabelProps) => {
    const { network } = useWeb3();
    const route = transactionUri(txHash, network ?? 4);

    return (
        <HStack {...props}>
            <Link href={route} isExternal>
                {shortAddress(txHash)} <ExternalLinkIcon mx="2px" />
            </Link>
        </HStack>
    );
};
