import { Modal } from '@chakra-ui/modal';
import { ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useUserData } from '../../hooks/useUserData';
import { ClaimDetails } from './claim-details-modal/claim-details';

export default ({ remittanceAddress, onCloseCallback }: { remittanceAddress: string; onCloseCallback: () => void }) => {
    const { findClaim } = useUserData();
    const remittance = findClaim(remittanceAddress);

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const handleClose = () => {
        onClose();
        onCloseCallback();
    };

    return (
        <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} size="2xl" onClose={handleClose} motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent py="4" px="2">
                <ClaimDetails claim={remittance} handleClose={handleClose} isRemittancePopup />
            </ModalContent>
        </Modal>
    );
};
