import { ContractReceipt } from '@ethersproject/contracts';
import React, { useMemo } from 'react';
import { EthAddress } from '../data-lib/ethereum';

export type PendingTxn = {
    pending?: boolean;
    hash?: EthAddress;
    receipt?: ContractReceipt;
    failed?: { reason: string; hasFailed: boolean };
};

export type SetPendingTxn = (pendingTxn: PendingTxn) => void;
export type SetLoading = (isLoading: boolean) => void;

export type UIState = {
    setPendingTxn: SetPendingTxn;
    pendingTxn: PendingTxn;
    setIsLoading: SetLoading;
    isLoading: boolean;
};

export const initUIState: UIState = {
    setPendingTxn: () => undefined,
    pendingTxn: {},
    setIsLoading: () => undefined,
    isLoading: false,
};

export const UIContext = React.createContext<UIState>(initUIState);

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
    const [pendingTxn, setPendingTxn] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    const context = useMemo(() => ({ ...initUIState, isLoading, setIsLoading, pendingTxn, setPendingTxn }), [pendingTxn, isLoading]);

    return <UIContext.Provider value={context}>{children}</UIContext.Provider>;
};

export const useUIState = () => {
    const context = React.useContext(UIContext);
    if (context === undefined) throw new Error('useUIState must me used within the UI provider');
    return context;
};
