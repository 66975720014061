import '@fontsource/inter';
import '@fontsource/rubik';
import 'focus-visible/dist/focus-visible';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BullaViewById from './components/display/bulla-view/by-bullaId';
import Payables from './components/display/bulla-view/payables';
import Payments from './components/display/bulla-view/payments';
import Receivables from './components/display/bulla-view/receivables';
import { PageLayout } from './components/layout/page-layout';
import { useWeb3 } from './hooks/useWeb3';
import Home from './pages/home/home';
import { AppProvider } from './state/app-state';
import { UIProvider } from './state/ui-state';

export const App = () => {
    const { wallet, initialized } = useWeb3();

    return (
        <>
            <AppProvider>
                <UIProvider>
                    <PageLayout isLoading={false}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path={'/payables/:tab'} component={Payables} />
                            <Route path={'/receivables/:tab'} component={Receivables} />
                            <Route path={'/payments/:tab'} component={Payments} />
                            <Route path={'/bulla/:bullaId/:tab'} component={BullaViewById} />
                            <Redirect to="/" />
                        </Switch>
                        {/* //TODO: add a page not found page */}
                    </PageLayout>
                </UIProvider>
            </AppProvider>
            {initialized && !wallet && <Redirect to="/onboard" />}
        </>
    );
};
