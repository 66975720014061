import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, FormControl, FormErrorMessage, FormLabel, HStack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import UploadIcon from 'url:../../../assets/upload.svg';
import { BullaClaimInfo } from '../../../data-lib/data-transforms';
import { addressEquality } from '../../../data-lib/ethereum';
import { useClaimFunction } from '../../../hooks/useBullaClaim';
import { useUserData } from '../../../hooks/useUserData';
const FILE_SIZE_MB = 100 * 1000 * 1024; //TODO: what should this be?

const UploadFileToClaim = ({ claim }: { claim: BullaClaimInfo }) => {
    const [file, setFile] = React.useState<File | undefined>();
    const fileRef = React.useRef<null | HTMLInputElement>(null);
    const selectFile = () => fileRef && fileRef.current && fileRef.current.click();
    const [processing, { addMultihash }] = useClaimFunction();
    const isTooLarge = file && file.size > FILE_SIZE_MB ? true : false;
    const shouldUpload = file && !isTooLarge ? true : false;

    return (
        <FormControl isInvalid={isTooLarge}>
            <FormLabel htmlFor="file">
                <HStack>
                    <Text>Upload File </Text>
                    <Text textStyle="faint" textDecoration="underline">
                        Is this right for me?
                    </Text>
                </HStack>
                <Text fontSize=".8em">{!isTooLarge && file?.name}</Text>
            </FormLabel>
            <ButtonGroup>
                <Button onClick={() => selectFile()} disabled={processing} colorScheme={'gray'}>
                    Select
                    <input
                        data-testid="fileInput"
                        type={'file'}
                        hidden
                        multiple={false}
                        ref={fileRef}
                        onChange={e => setFile(e.currentTarget?.files?.[0])}
                    />
                </Button>
                {file && (
                    <Button
                        data-testid="triggerAddMultihash"
                        rightIcon={<Image src={UploadIcon} />}
                        onClick={() => addMultihash(claim.claimAddress, file)}
                        isDisabled={!shouldUpload}
                        isLoading={processing}
                        colorScheme={'orange'}
                    >
                        Upload file
                    </Button>
                )}
            </ButtonGroup>
            {isTooLarge && <FormErrorMessage>File is too large</FormErrorMessage>}
        </FormControl>
    );
};

export const ViewFile = ({ claim }: { claim: BullaClaimInfo }) => {
    const { ipfsHash } = claim;
    return (
        <Text
            data-testid="viewFileButton"
            as="button"
            textStyle="underline"
            onClick={() => window.open(`https://ipfs.infura.io/ipfs/${ipfsHash}`, '_blank')}
        >
            View File <ExternalLinkIcon mt={'-1'} />
        </Text>
    );
};

export const FileUpload = ({ claim }: { claim: BullaClaimInfo }) => {
    const { userAddress } = useUserData();
    const hasFile = !!claim.ipfsHash;
    const isOwner = addressEquality(userAddress, claim.claimOwner);

    return (
        <>
            {isOwner && !hasFile && <UploadFileToClaim claim={claim} />}
            {hasFile && <ViewFile claim={claim} />}
        </>
    );
};
