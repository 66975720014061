import moment from 'moment';

export const toDateTimeFormat = (dateTime: Date, type?: 'long' | 'short') => {
    const longOpt = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const shortOpt = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const options = type === 'short' ? shortOpt : longOpt;
    return new Intl.DateTimeFormat('default', options).format(dateTime);
};

export const toDateDisplay = (date: Date | undefined) => (date ? moment(date).format('DD MMM YYYY') : '');

export function toRecord<
    T extends { [K in keyof T]: string | number | symbol }, // added constraint
    K extends keyof T,
>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => ((acc[item[selector]] = item), acc), {} as Record<T[K], T>);
}
