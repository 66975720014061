import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    ChakraStyleProps,
    Divider,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Heading,
    HStack,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Textarea,
    useDisclosure
} from '@chakra-ui/react';
import { isAddress as validEthereumAddress } from '@ethersproject/address';
import { Field, FieldProps, Form, Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import CalendarIcon from 'url:../../assets/calendar.svg';
import * as Yup from 'yup';
import { ClaimType, mapTxnReceiptToClaimEvent } from '../../data-lib/data-transforms';
import { addressEquality, EthAddress } from '../../data-lib/ethereum';
import { addDaysToToday } from '../../data-lib/helpers';
import { networks } from '../../data-lib/networks';
import { useCreateBullaClaim } from '../../hooks/useCreateBullaClaim';
import useEmail from '../../hooks/useEmail';
import { useUserData } from '../../hooks/useUserData';
import { useWeb3 } from '../../hooks/useWeb3';
import { useUIState } from '../../state/ui-state';
import DatePicker from '../date-picker';
import RemittanceModal from './remittance-created-modal';

type Claim = {
    claimAmount: number;
    debtor: string;
    description: string;
    dueBy: Date;
    emailAddress: string;
    emailMessage: string;
};

type CreateClaimModalProps = ChakraStyleProps & {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    claimType: ClaimType;
    bullaId: string | number;
};

const emptyInvoice = {
    claimAmount: 0,
    debtor: '',
    description: '',
    dueBy: addDaysToToday(7),
    emailAddress: '',
    emailMessage: '',
};

const errorMessageSchema = Yup.object().shape({
    debtor: Yup.string()
        .required('Required')
        .test('is-valid-address', 'Invalid wallet address', value => validEthereumAddress(value || '')),
    claimAmount: Yup.number().lessThan(10000000000, 'Amount too large').moreThan(0, 'Amount must be greater than 0'),
    description: Yup.string().required('Required'),
    dueBy: Yup.date().typeError('Invalid date'),
    emailAddress: Yup.string().email('Invalid email address').optional(),
    emailMessage: Yup.string().optional(),
});

export const NewAddressAlert = ({ newAddress }: { newAddress: string }) => {
    const { payables, receivables, userAddress } = useUserData();
    const hasPaidUser = React.useMemo(() => {
        return [...payables, ...receivables].reduce((hasPaid, claim) => {
            const { debtor, creditor, claimOwner } = claim;
            const relatedToClaim =
                addressEquality(newAddress, debtor) ||
                addressEquality(newAddress, creditor) ||
                addressEquality(newAddress, claimOwner) ||
                addressEquality(newAddress, userAddress)
                    ? true
                    : false;
            return hasPaid || relatedToClaim;
        }, false);
    }, [payables, receivables]);

    return (
        <>
            {!hasPaidUser && (
                <Alert status="info" mb="4">
                    <AlertIcon />
                    You have not interacted with this wallet before. Remember to verify all wallet addresses.
                </Alert>
            )}
        </>
    );
};

export const CreateClaimModal = ({ triggerElement, claimType, bullaId }: CreateClaimModalProps) => {
    const initialRef = React.useRef(null);
    const { network = 4, address } = useWeb3();
    const { pendingTxn } = useUIState();
    const transactionIsPending = !!pendingTxn?.pending;
    const { sendInvoiceNotification } = useEmail();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [creating, createClaim] = useCreateBullaClaim();
    const [remittanceAddress, setRemittanceAddress] = React.useState<string | undefined>();

    const handleSendNotification = (invoice: Claim, claimAddress: EthAddress) => {
        if (address) sendInvoiceNotification({ ...invoice, claimType: claimType, network, creditor: address, claimAddress });
    };

    const handleSend = async (invoice: Claim) => {
        const receipt = await createClaim(claimType, { bullaId: +bullaId, ...invoice });
        //we should probably have a helper function in data-transform or something for this.
        if (receipt) {
            const claimAddress: string | undefined =
                receipt && receipt.events?.find(evt => evt.event === 'NewBullaClaim')?.args?.bullaClaim;
            if (invoice.emailAddress && claimAddress) handleSendNotification(invoice, claimAddress);
        }
        return receipt;
    };

    const handleInvoiceComplete = (resetForm: () => void) => {
        setRemittanceAddress(undefined);
        onClose();
        resetForm();
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal
                isCentered
                closeOnOverlayClick={false}
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                motionPreset="slideInBottom"
                size="4xl"
                closeOnEsc
            >
                <ModalOverlay />
                <Formik
                    initialValues={emptyInvoice}
                    validationSchema={errorMessageSchema}
                    onSubmit={async (invoice, { resetForm }) => {
                        const receipt = await handleSend(invoice);
                        if (isOpen) {
                            if (claimType === 'Invoice') handleInvoiceComplete(resetForm);
                            if (claimType === 'Remittance' && receipt) setRemittanceAddress(mapTxnReceiptToClaimEvent(receipt)?.bullaClaim);
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue, isValid, values, resetForm }) => (
                        <>
                            <Form>
                                <ModalContent py="8" px="6">
                                    <ModalHeader>
                                        <Heading color="icon_dark" textStyle="labelLg">
                                            New {claimType}
                                        </Heading>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody pb={6}>
                                        {touched.debtor && values.debtor && <NewAddressAlert newAddress={values.debtor} />}
                                        <Stack spacing="2" minW="fit-content" maxW="60%">
                                            <Field name="debtor">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isRequired isInvalid={!!errors.debtor}>
                                                        <FormLabel htmlFor="debtor">
                                                            Recipient Address
                                                            <QuestionOutlineIcon as="button" role="button" pos="absolute" ml="6" mt="1" />
                                                        </FormLabel>
                                                        <InputGroup>
                                                            <Input
                                                                ref={initialRef}
                                                                placeholder="Ethereum Address"
                                                                id="debtor"
                                                                {...field}
                                                                isDisabled={transactionIsPending}
                                                                onChange={e => setFieldValue('debtor', e.target.value.trim())}
                                                                data-testid='claimRecipient'
                                                            />
                                                            {/* <InputRightElement
                                                            borderLeft=".4px solid #E2E8F0"
                                                            h="100%"
                                                            w="4em"
                                                            pointerEvents="none"
                                                        >
                                                            <IconButton
                                                                variant="ghost"
                                                                aria-label="address book"
                                                                icon={<Image src={AddressBookIcon} />}
                                                            />
                                                        </InputRightElement> */}
                                                        </InputGroup>
                                                        {touched.debtor && <FormErrorMessage>{errors.debtor}</FormErrorMessage>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="claimAmount">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isRequired isInvalid={!!errors.claimAmount && touched.claimAmount}>
                                                        <FormLabel htmlFor="claimAmount">Amount</FormLabel>
                                                        <HStack>
                                                            <InputGroup>
                                                                <Input
                                                                    placeholder="0"
                                                                    type="number"
                                                                    isDisabled={transactionIsPending}
                                                                    data-testid='claimAmount'
                                                                    {...field}
                                                                />
                                                                <InputRightElement mr="2" children={networks[network].currency} />
                                                            </InputGroup>
                                                        </HStack>
                                                        {touched.claimAmount && <FormErrorMessage>{errors.claimAmount}</FormErrorMessage>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="description">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isRequired isInvalid={!!errors.description && touched.description}>
                                                        <FormLabel htmlFor="description">Description</FormLabel>
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="Enter Description"
                                                                isDisabled={transactionIsPending}
                                                                data-testid='claimDescription'
                                                                {...field}
                                                            />
                                                        </InputGroup>
                                                        {touched.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            {claimType === 'Invoice' && (
                                                <Field name="dueBy">
                                                    {({ field }: FieldProps) => (
                                                        <FormControl isRequired isInvalid={!!errors.dueBy}>
                                                            <FormLabel htmlFor="dueBy">Due Date</FormLabel>
                                                            <InputGroup maxW="fit-content">
                                                                <DatePicker
                                                                    selectedDate={field.value}
                                                                    onChange={date => setFieldValue('dueBy', date)}
                                                                    dateFormat="dd MMM yyyy"
                                                                />
                                                                <InputRightElement
                                                                    borderLeft=".4px solid #E2E8F0"
                                                                    h="100%"
                                                                    w="4em"
                                                                    pointerEvents="none"
                                                                    children={<Image src={CalendarIcon} />}
                                                                />
                                                            </InputGroup>
                                                            {touched.dueBy && <FormErrorMessage>{errors.dueBy}</FormErrorMessage>}
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            )}
                                            <Box h="4" />
                                        </Stack>
                                        <Divider my="4" />
                                        <Stack spacing="2" minW="fit-content" maxW="60%">
                                            <Field name="emailAddress">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isInvalid={!!errors.emailAddress && touched.emailAddress}>
                                                        <FormLabel htmlFor="emailAddress">
                                                            Recipient Email
                                                            <FormHelperText pos="absolute" top="-.32em" ml="8.75em">
                                                                (Optional)
                                                            </FormHelperText>
                                                            <QuestionOutlineIcon as="button" role="button" pos="absolute" ml="20" mt="1" />
                                                        </FormLabel>
                                                        <InputGroup>
                                                            <Input
                                                                isDisabled={transactionIsPending}
                                                                placeholder="email@example.com"
                                                                {...field}
                                                            />
                                                        </InputGroup>
                                                        {touched.emailAddress && <FormErrorMessage>{errors.emailAddress}</FormErrorMessage>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="emailMessage">
                                                {({ field }: FieldProps) => (
                                                    <FormControl>
                                                        <FormLabel htmlFor="emailMessage">Message</FormLabel>
                                                        <InputGroup>
                                                            <Textarea
                                                                isDisabled={!!errors.emailAddress || !values.emailAddress}
                                                                placeholder="Enter message"
                                                                {...field}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Stack>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            colorScheme="white"
                                            color="dark"
                                            border="1px"
                                            borderColor="dark"
                                            px="8"
                                            py="6"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Spacer />
                                        {/* <Button variant="link" mx="8" my="6" textStyle="dark" fontWeight="500">
                                        Save as Draft
                                    </Button> */}
                                        <Button
                                            px="8"
                                            py="6"
                                            fontWeight="500"
                                            colorScheme="dark"
                                            type="submit"
                                            isLoading={creating}
                                            isDisabled={_.isEqual(values, emptyInvoice) || !isValid || creating || !!remittanceAddress}
                                            data-testid='triggerCreateInvoice'
                                        >
                                            Send
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                            {remittanceAddress && (
                                <RemittanceModal
                                    remittanceAddress={remittanceAddress}
                                    onCloseCallback={() => handleInvoiceComplete(resetForm)}
                                />
                            )}
                        </>
                    )}
                </Formik>
            </Modal>
        </>
    );
};
