import { Provider } from '@ethersproject/abstract-provider';
import { Event, utils } from 'ethers';
import { BytesLike, Interface, LogDescription } from 'ethers/lib/utils';
import { bullaClaimInterface, bullaGroupInterface, bullaManagerInterface } from '../data-lib/helpers';
import {
    ClaimActionEvent,
    mapToClaimActionEvent,
    mapToMultihashAddedEvent,
    mapToNewBullaClaimEvent,
    mapToNewBullaEvent,
    mapToNewBullaGroupEvent,
    MultihashAddedEvent,
    NewBullaClaimEvent,
    NewBullaEvent,
    NewBullaGroupEvent,
} from './event-mapping';

export type EventType = 'NewBullaGroup' | 'NewBulla' | 'NewDebtorClaim' | 'NewCreditorClaim' | 'ClaimAction' | 'MultihashAdded';

export type MappedEventType = NewBullaEvent | NewBullaClaimEvent | ClaimActionEvent | NewBullaGroupEvent | MultihashAddedEvent | undefined;

export type EventData = {
    eventType: EventType;
    eventSignature: string;
    abiInterface: Interface;
};

export type TopicStrings = Array<string | null>;

export type EventLog = Event | LogDescription;

export type EventLogs = {
    eventType: EventType;
    events: MappedEventType[];
};
export type RecordEventLogs = Record<EventType, EventLogs>;
export const getNewBullaGroupEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['NewBullaGroup']?.events as NewBullaGroupEvent[]) || [];
};
export const getNewBullaEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['NewBulla']?.events as NewBullaEvent[]) || [];
};
export const getNewBullaClaimEvents = (eventLogs: RecordEventLogs) => {
    const allRelatedClaims = [...eventLogs['NewCreditorClaim'].events, ...eventLogs['NewDebtorClaim'].events];
    const onlyUnique = (allRelatedClaims as NewBullaClaimEvent[]).reduce(
        (claimMap, claim) => ({ ...claimMap, [claim.bullaClaim]: claim }),
        {},
    );
    return Object.values(onlyUnique as NewBullaClaimEvent[]);
};
export const getNewDebtorClaimEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['NewDebtorClaim']?.events as NewBullaClaimEvent[]) || [];
};
export const getNewCreditorClaimEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['NewCreditorClaim']?.events as NewBullaClaimEvent[]) || [];
};
export const getClaimActionEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['ClaimAction']?.events as ClaimActionEvent[]) || [];
};
export const getClaimMultihashEvents = (eventLogs: RecordEventLogs) => {
    return (eventLogs['MultihashAdded']?.events as MultihashAddedEvent[] | []) || [];
};
export const formatEventSig = (eventSignature: string) => utils.id(eventSignature);
export const formatTopicString = (value: string | number) => utils.hexZeroPad(value as BytesLike, 32);

export const events: Record<EventType, EventData> = {
    NewBulla: {
        eventType: 'NewBulla',
        eventSignature: 'NewBulla(address,address,uint256,address,string,uint256,uint256)',
        abiInterface: bullaGroupInterface,
    },
    NewCreditorClaim: {
        eventType: 'NewCreditorClaim',
        eventSignature: 'NewBullaClaim(address,address,uint256,address,address,address,address,string,uint256,uint256,uint256)',
        abiInterface: bullaGroupInterface,
    },
    NewDebtorClaim: {
        eventType: 'NewDebtorClaim',
        eventSignature: 'NewBullaClaim(address,address,uint256,address,address,address,address,string,uint256,uint256,uint256)',
        abiInterface: bullaGroupInterface,
    },
    NewBullaGroup: {
        eventType: 'NewBullaGroup',
        eventSignature: 'NewBullaGroup(address,address,address,string,bytes32,bool,uint256)',
        abiInterface: bullaManagerInterface,
    },
    ClaimAction: {
        eventType: 'ClaimAction',
        eventSignature: 'ClaimAction(address,address,uint256,address,uint8,uint256,uint8,uint256)',
        abiInterface: bullaClaimInterface,
    },
    MultihashAdded: {
        eventType: 'MultihashAdded',
        eventSignature: 'MultihashAdded(address,address,(bytes32,uint8,uint8),uint256)',
        abiInterface: bullaClaimInterface,
    },
};

export const getTopicStrings = (creatorContract: string = '', userAddress: string, bullaId?: number): Record<EventType, TopicStrings> => ({
    NewBulla: [formatEventSig(events.NewBulla.eventSignature), formatTopicString(creatorContract)],
    NewCreditorClaim: [
        formatEventSig(events.NewCreditorClaim.eventSignature),
        formatTopicString(creatorContract),
        formatTopicString(userAddress),
    ],
    NewDebtorClaim: [
        formatEventSig(events.NewDebtorClaim.eventSignature),
        formatTopicString(creatorContract),
        null,
        formatTopicString(userAddress),
    ],
    NewBullaGroup: [formatEventSig(events.NewBullaGroup.eventSignature), formatTopicString(creatorContract)],
    ClaimAction: [
        formatEventSig(events.ClaimAction.eventSignature),
        formatTopicString(creatorContract),
        null,
        bullaId !== undefined ? formatTopicString(bullaId) : null,
    ],
    MultihashAdded: [formatEventSig(events.MultihashAdded.eventSignature), formatTopicString(creatorContract)],
});

export const mapEventFromLog = (log: EventLog, eventType: EventType, txHash: string): MappedEventType => {
    switch (eventType) {
        case 'NewBullaGroup':
            return mapToNewBullaGroupEvent(log.args);
        case 'NewBulla':
            return mapToNewBullaEvent(log.args);
        case 'NewDebtorClaim':
        case 'NewCreditorClaim':
            return mapToNewBullaClaimEvent(log.args);
        case 'ClaimAction':
            return mapToClaimActionEvent(log.args); //,
        case 'MultihashAdded':
            return mapToMultihashAddedEvent(log.args);
    }
};

export const getAllHistoricalLogs = async (provider: Provider, topicStrings: Record<EventType, TopicStrings>, fromBlock?: number) => {
    const getHistoricalLogs = async (eventType: EventType, abiInterface: utils.Interface) => {
        const logs = await provider.getLogs({
            topics: topicStrings[eventType],
            fromBlock: fromBlock ?? 0,
        });
        return logs.map(log => mapEventFromLog(abiInterface.parseLog(log), eventType, log.transactionHash));
    };

    const histLogs = await Promise.all(
        Object.values(events).map(async ({ eventType, abiInterface }) => ({
            eventType,
            events: await getHistoricalLogs(eventType, abiInterface),
        })),
    );

    console.log(histLogs.reduce((logger, eventType) => ({ ...logger, [eventType.eventType]: eventType.events.length }), {}));

    return histLogs.reduce(
        (acc, log) => ({
            ...acc,
            [log.eventType]: log,
        }),
        {} as Record<EventType, EventLogs>,
    );
};
