import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/datepicker.css';
interface DatePickerProps extends ReactDatePickerProps {
    selectedDate: Date | undefined;
    isClearable?: boolean;
    onChange: (date: Date) => any;
    showPopperArrow?: boolean;
    dateFormat?: string;
}
export default ({ selectedDate, isClearable, showPopperArrow, onChange, dateFormat, ...overrides }: DatePickerProps) => (
    <DatePicker
        calendarClassName={'chakra-text'}
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        dateFormat={dateFormat}
        {...overrides}
    />
);
