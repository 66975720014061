import { ContractReceipt, ContractTransaction } from 'ethers';
import React from 'react';
import { addMultihash, payClaim, rejectClaim, rescindClaim } from '../data-lib/bulla-claim';
import { getBullaClaim } from '../data-lib/helpers';
import { uploadFileToIpfs } from '../data-lib/mutlihash';
import { useUIState } from '../state/ui-state';
import { useWeb3 } from './useWeb3';

export const useClaimFunction = () => {
    const [doing, setDoing] = React.useState<boolean>(false);
    const { provider, address } = useWeb3();
    const { setPendingTxn } = useUIState();

    const signer = provider?.getSigner();
    if (!signer) throw new Error('signer is required');
    const setTx = (tx: ContractTransaction) => setPendingTxn({ hash: tx.hash, pending: true });

    const uiHandler = async (
        claimFunction: () => Promise<{
            receipt: ContractReceipt;
        }>,
    ) => {
        if (signer && address) {
            try {
                setDoing(true);
                const response = await claimFunction();
                setPendingTxn({ pending: false, hash: response?.receipt.transactionHash, receipt: response?.receipt });
            } catch (metaMaskError) {
                console.error('error occurred while executing function on bulla claim', metaMaskError);
                try {
                    const errorString = metaMaskError.message.substring(
                        metaMaskError.message.lastIndexOf('error=') + 6,
                        metaMaskError.message.lastIndexOf(', method'),
                    );
                    const { message } = JSON.parse(errorString);
                    setPendingTxn({ pending: false, failed: { reason: message, hasFailed: true } });
                } catch (e) {
                    console.error('could not parse message', e);
                    setPendingTxn({ pending: false, failed: { reason: metaMaskError.message, hasFailed: true } });
                }
            } finally {
                setDoing(false);
            }
        }
    };

    const functions = {
        payClaim: (claimAddress: string, paymentAmount: number) =>
            uiHandler(() => payClaim(setTx, { bullaClaim: getBullaClaim(claimAddress), paymentAmount, signer })),
        rejectClaim: (claimAddress: string) => uiHandler(() => rejectClaim(setTx, { bullaClaim: getBullaClaim(claimAddress), signer })),
        rescindClaim: (claimAddress: string) => uiHandler(() => rescindClaim(setTx, { bullaClaim: getBullaClaim(claimAddress), signer })),
        addMultihash: (claimAddress: string, file: File) =>
            uiHandler(async () =>
                uploadFileToIpfs(file).then(ipfsHash => addMultihash(setTx, { bullaClaim: getBullaClaim(claimAddress), ipfsHash, signer })),
            ),
    };

    return [doing, functions] as const;
};
