import { Button, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { InfoSnippetCard, ListViewCard } from '../../components/layout/cards';
import { NavButton } from '../../components/layout/header';
import { MaxWidthWrapper, SummaryPanel } from '../../components/layout/page-layout';
import { CreateBullaModal } from '../../components/modals/create-bulla-modal';
import { useUserData } from '../../hooks/useUserData';
import { useUserSummary } from '../../hooks/useUserSummary';
import { toDateDisplay } from '../../tools/common';
import { bullasToListItems, paidReceivablesToListItems, paymentsMadeToListItems, pendingClaimsToListItems } from './transforms';

const SummaryCards = () => {
    const { payableTotals, receivableTotals, netBalance, bullaSummary } = useUserSummary();

    return (
        <>
            <Flex wrap="wrap" mx="-3">
                <Flex basis="50%" wrap="wrap" grow={1} align="center">
                    <InfoSnippetCard
                        isCurrency
                        title="Total Payable"
                        value={payableTotals.total}
                        subtitle={`${payableTotals.count.toString()} Payable${payableTotals.count > 1 ? 's' : ''}`}
                    />
                    <InfoSnippetCard
                        isCurrency
                        title="Total Receivables"
                        value={receivableTotals.total}
                        subtitle={`${receivableTotals.count.toString()} Receivable${receivableTotals.count > 1 ? 's' : ''}`}
                    />
                </Flex>
                <Flex basis="50%" wrap="wrap" grow={1} align="center">
                    <InfoSnippetCard
                        isCurrency
                        title="Total Net Balance"
                        value={netBalance.balance}
                        subtitle={`Last Updated: ${toDateDisplay(netBalance.lastUpdated)}`}
                    />
                    <InfoSnippetCard
                        title="Bullas Created"
                        value={bullaSummary.count}
                        subtitle={`Last Created: ${toDateDisplay(bullaSummary.lastCreated)}`}
                        bg="footerBg"
                    />
                </Flex>
            </Flex>
        </>
    );
};

export default () => {
    const { bullas, receivables, payables, userAddress } = useUserData();
    const bullasListItems = bullasToListItems(bullas);
    const pendingPayables = pendingClaimsToListItems(payables, userAddress);
    const pendingReceivables = pendingClaimsToListItems(receivables, userAddress);
    const paidReceivables = paidReceivablesToListItems(receivables);
    const paymentsMade = paymentsMadeToListItems(payables);
    return (
        <>
            <SummaryPanel>
                <Flex>
                    <Heading color="heading">Home</Heading>
                    <Spacer />
                    <CreateBullaModal
                        triggerElement={onOpen => (
                            <Button onClick={onOpen} colorScheme={'accent'} h="12" p="6" w="auto" data-testid="createBullaButton">
                                <Text fontWeight="700">Create Bulla</Text>
                            </Button>
                        )}
                    />
                </Flex>
                <SummaryCards />
            </SummaryPanel>
            <MaxWidthWrapper p="8">
                <ListViewCard
                    title={<Text textStyle="listTitle">Bullas</Text>}
                    headers={['bulla name', 'payable', 'receivable', 'net balance']}
                    listItems={bullasListItems}
                    emptyMessage={
                        <Text textStyle="faint" fontWeight="500">
                            You have not created any Bullas yet.{' '}
                            <CreateBullaModal
                                triggerElement={onOpen => (
                                    <Text as="button" onClick={onOpen} color="scheme.accent_dark" fontWeight="500" textStyle="underline">
                                        Create One Now
                                    </Text>
                                )}
                            />
                        </Text>
                    }
                />
                <ListViewCard
                    title={
                        <Flex>
                            <Text textStyle="listTitle">Pending Payables</Text>
                            <Spacer />
                            <NavButton to="/payables/invoices" textStyle="underline">
                                View All Payables
                            </NavButton>
                        </Flex>
                    }
                    headers={['due date', 'to wallet', 'bulla name', 'description', 'amount']}
                    listItems={pendingPayables}
                    emptyMessage={<Text textStyle="faint">You have no pending payables</Text>}
                />
                <ListViewCard
                    title={
                        <Flex>
                            <Text textStyle="listTitle">Pending Receivables</Text>
                            <Spacer />
                            <NavButton to="/receivables/invoices" textStyle="underline">
                                View All Receivables
                            </NavButton>
                        </Flex>
                    }
                    headers={['due date', 'from wallet', 'bulla name', 'description', 'amount']}
                    listItems={pendingReceivables}
                    emptyMessage={<Text textStyle="faint">You have no pending receivables</Text>}
                />
                <ListViewCard
                    title={
                        <Flex>
                            <Text textStyle="listTitle">Received Payments (Last 30 days)</Text>
                            <Spacer />
                            <NavButton to="/payments/received" textStyle="underline">
                                View All Payments Received
                            </NavButton>
                        </Flex>
                    }
                    headers={['received', 'from wallet', 'description', 'amount']}
                    listItems={paidReceivables}
                    emptyMessage={<Text textStyle="faint">You have not received any payments in the last 30 days</Text>}
                />
                <ListViewCard
                    title={
                        <Flex>
                            <Text textStyle="listTitle">Payments Made (Last 30 days)</Text>
                            <Spacer />
                            <NavButton to="/payments/made" textStyle="underline">
                                View All Payments Made
                            </NavButton>
                        </Flex>
                    }
                    headers={['paid', 'to wallet', 'description', 'amount']}
                    listItems={paymentsMade}
                    emptyMessage={<Text textStyle="faint">You have not received any payments in the last 30 days</Text>}
                />
            </MaxWidthWrapper>
        </>
    );
};
