import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddressLabel } from '../../components/base/address-label';
import { Currency } from '../../components/currency/currency';
import { PopoverAction } from '../../components/display/actionPopover';
import { ListItemProps } from '../../components/layout/cards';
import { CreateClaimModal } from '../../components/modals/create-claim-modal';
import { BullaClaimInfo, BullaInfo } from '../../data-lib/data-transforms';
import { addressEquality, EthAddress } from '../../data-lib/ethereum';
import { ActionType } from '../../data-lib/event-mapping';
import { addDaysToToday } from '../../data-lib/helpers';
import { useOpenClaimDetails } from '../../hooks/useClaimDetailDisclosure';
import { useUserSummary } from '../../hooks/useUserSummary';
import { toDateDisplay } from '../../tools/common';

export const bullasToListItems = (bullas: BullaInfo[]): ListItemProps[] | [] => {
    const history = useHistory();
    const { bullaSummary } = useUserSummary();

    return bullas.reduce((listItems: ListItemProps[], { bullaId, description, bullaOwner }: BullaInfo) => {
        const { payable, receivable, netBalance } = bullaSummary[bullaId]
            ? bullaSummary[bullaId]
            : { payable: 0, receivable: 0, netBalance: 0 };

        const columnValues = [
            <Box textStyle="link" onClick={() => history.push(`/bulla/${bullaId}/invoices`)} as="button" data-testid={description}>
                {description}
            </Box>,
            <Currency value={payable} />,
            <Currency value={receivable} />,
            <Currency value={netBalance} />,
        ];

        const actions = [
            <PopoverAction onClick={() => history.push({ pathname: `bulla/${bullaId}/invoices` })}>View Invoices</PopoverAction>,
            <CreateClaimModal
                bullaId={bullaId}
                claimType="Invoice"
                triggerElement={onOpen => <PopoverAction onClick={onOpen}>Create Invoice</PopoverAction>}
            />,
            <PopoverAction onClick={() => history.push({ pathname: `bulla/${bullaId}/remittances` })}>View Remittances</PopoverAction>,
            <CreateClaimModal
                bullaId={bullaId}
                claimType="Remittance"
                triggerElement={onOpen => <PopoverAction onClick={onOpen}>Create Remittance</PopoverAction>}
            />,
        ];

        return [...listItems, { columnValues, actions }];
    }, []);
};

export const pendingClaimsToListItems = (claims: BullaClaimInfo[], userAddress: EthAddress) => {
    const openClaim = useOpenClaimDetails();
    const pendingClaims = claims
        .filter(claim => claim.claimStatus === 'Pending')
        .sort((a, b) => a.dueBy.getTime() - b.dueBy.getTime())
        .slice(0, 5);
    return pendingClaims.reduce((listItems, claim) => {
        const { dueBy, creditor, debtor, description, claimAmount, bullaDescription, claimAddress } = claim;
        const walletToShow = addressEquality(userAddress, creditor) ? debtor : creditor;
        const dueDate = toDateDisplay(dueBy);
        const columnValues = [
            <Text>{dueDate}</Text>,
            <AddressLabel ethAddress={walletToShow} />,
            <Text>{bullaDescription}</Text>,
            <Text>{description}</Text>,
            <HStack w="100%">
                <Currency value={claimAmount} />
                <Spacer />
                <IconButton
                    variant="ghost"
                    borderRadius="full"
                    aria-label="expand"
                    onClick={() => openClaim(claimAddress)}
                    icon={<ChevronRightIcon />}
                />
            </HStack>,
        ];
        return [...listItems, { columnValues }];
    }, [] as ListItemProps[]);
};

export const paidReceivablesToListItems = (receivables: BullaClaimInfo[]): ListItemProps[] | [] => {
    const openClaim = useOpenClaimDetails();
    const paidReceivables = receivables
        .map(receivable => {
            const lastPaymentDate =
                receivable.claimActions.find(action => action.actionType == ActionType.Payment)?.blocktime ?? new Date(0);
            return {
                ...receivable,
                lastPaymentDate,
            };
        })
        .filter(({ lastPaymentDate }) => lastPaymentDate > addDaysToToday(-30))
        .sort((a, b) => b.lastPaymentDate.getTime() - a.lastPaymentDate.getTime())
        .slice(0, 5);

    return paidReceivables.reduce((listItems, receivable) => {
        const { claimActions, debtor, description, claimAmount, claimAddress } = receivable;
        const receivedDate = toDateDisplay(claimActions[0].blocktime);
        const columnValues = [
            <Text>{receivedDate}</Text>,
            <AddressLabel ethAddress={debtor} />,
            <Text>{description}</Text>,
            <HStack w="100%">
                <Currency value={claimAmount} />
                <Spacer />
                <IconButton
                    variant="ghost"
                    borderRadius="full"
                    aria-label="expand"
                    onClick={() => openClaim(claimAddress)}
                    icon={<ChevronRightIcon />}
                />
            </HStack>,
        ];
        return [...listItems, { columnValues }];
    }, [] as ListItemProps[]);
};

export const paymentsMadeToListItems = (payables: BullaClaimInfo[]): ListItemProps[] | [] => {
    const openClaim = useOpenClaimDetails();
    const paidPayables = payables
        .map(payable => {
            const lastPaymentDate = payable.claimActions.find(action => action.actionType == ActionType.Payment)?.blocktime ?? new Date(0);
            return {
                ...payable,
                lastPaymentDate,
            };
        })
        .filter(({ lastPaymentDate }) => lastPaymentDate > addDaysToToday(-30))
        .slice(0, 5);
    return paidPayables.reduce((listItems, payable) => {
        const { lastPaymentDate, creditor, description, claimAmount, claimAddress } = payable;
        const receivedDate = toDateDisplay(lastPaymentDate);
        const columnValues = [
            <Text>{receivedDate}</Text>,
            <AddressLabel ethAddress={creditor} />,
            <Text>{description}</Text>,
            <HStack w="100%">
                <Currency value={claimAmount} />
                <Spacer />
                <IconButton
                    variant="ghost"
                    borderRadius="full"
                    aria-label="expand"
                    onClick={() => openClaim(claimAddress)}
                    icon={<ChevronRightIcon />}
                />
            </HStack>,
        ];
        return [...listItems, { columnValues }];
    }, [] as ListItemProps[]);
};
