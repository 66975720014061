import { maxBy } from 'lodash';
import { useEffect, useState } from 'react';
import { BullaClaimInfo, BullaInfo, getPayables, getReceivables } from '../data-lib/data-transforms';
import { UserData, useUserData } from './useUserData';
import { useWeb3 } from './useWeb3';

export type UserSummary = {
    payableTotals: { total: number; count: number };
    receivableTotals: { total: number; count: number };
    netBalance: { balance: number; lastUpdated?: Date };
    bullaSummary: { count: number; [bullaId: number]: BullaSummary; lastCreated?: Date };
};

export type BullaSummary = {
    payable: number;
    receivable: number;
    netBalance: number;
};

const claimInfoOutstanding = (claims: BullaClaimInfo[]) =>
    claims.reduce((acc, { claimStatus, claimAmount, paidAmount }) => {
        const amtPending = claimStatus !== 'Rejected' && claimStatus !== 'Rescinded' ? claimAmount - paidAmount : 0;
        return acc + amtPending;
    }, 0);

const getUserSummary = ({ payables, receivables, bullas, userAddress }: UserData, walletBalance: number): UserSummary => {
    const payableTotal = claimInfoOutstanding(payables);
    const receivableTotal = claimInfoOutstanding(receivables);
    const getBullaSummary = (bullas: BullaInfo[]) =>
        bullas.reduce((summary, bulla) => {
            const payable = claimInfoOutstanding(getPayables(userAddress, bulla.bullaClaims));
            const receivable = claimInfoOutstanding(getReceivables(userAddress, bulla.bullaClaims));
            const netBalance = receivable - payable;
            return {
                ...summary,
                [bulla.bullaId]: {
                    payable,
                    receivable,
                    netBalance,
                },
            };
        }, {});

    return {
        payableTotals: {
            total: payableTotal,
            count: payables.filter(p => p.claimStatus === 'Pending').length,
        },
        receivableTotals: {
            total: receivableTotal,
            count: receivables.filter(p => p.claimStatus === 'Pending').length,
        },
        netBalance: {
            balance: receivableTotal - payableTotal + walletBalance,
            lastUpdated: maxBy([...payables, ...receivables], claim => claim.created)?.created,
        },
        bullaSummary: {
            count: bullas.length,
            ...getBullaSummary(bullas),
            lastCreated: maxBy(bullas, bulla => bulla.created)?.created,
        },
    };
};

const initUserSummary: UserSummary = {
    payableTotals: { total: 0, count: 0 },
    receivableTotals: { total: 0, count: 0 },
    netBalance: { balance: 0 },
    bullaSummary: { count: 0 },
};
//should I just call useUserData inside this hook?
export const useUserSummary = () => {
    const userData = useUserData();
    const { ethBalance = 0 } = useWeb3();
    const [userSummary, setUserSummary] = useState(initUserSummary);
    useEffect(() => {
        if (userData) {
            const summary = getUserSummary(userData, ethBalance);
            setUserSummary(summary);
        }
    }, [userData, ethBalance]);
    return userSummary;
};
