import { Box, Divider, Flex, Grid, GridItem, HStack, SlideFade, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { ChakraCompose } from '../../tools/types';
import { Currency } from '../currency/currency';
import { ActionPopover } from '../display/actionPopover';

export type InfoSnippetCardProps = {
    title: string;
    value: number;
    subtitle: string;
    isCurrency?: boolean;
} & ChakraCompose;

export const InfoSnippetCard = ({ title, value, subtitle, children, isCurrency, ...overrides }: InfoSnippetCardProps) => (
    <Flex bg="scheme.light" minW="80" h="40" borderRadius="lg" boxShadow="lg" p="4" m={3} grow={1} {...overrides}>
        <Stack>
            <b>{title}</b>
            <SlideFade in>
                {isCurrency ? <Currency value={value} textStyle="labelXl" /> : <Text textStyle="labelXl">{value}</Text>}
            </SlideFade>
            <Text>{subtitle}</Text>
        </Stack>
    </Flex>
);

export type ListItemProps = { columnValues: React.ReactNode[] | string[]; actions?: React.ReactNode[] };
export type ListViewCardProps = {
    title?: string | React.ReactNode;
    headers: string[];
    listItems: ListItemProps[];
    emptyMessage: React.ReactNode;
};

export const ListViewCard = ({ title, headers, listItems, emptyMessage }: ListViewCardProps) => (
    <Box>
        {title}
        <Box px="4" py="6" mt="2" mb="8" borderWidth="1px" borderColor="gray.400" borderRadius="md" data-testid="bullaList">
            <Grid templateColumns={`repeat(${headers.length}, 1fr)`} templateRows={`repeat(${listItems.length + 1}, 4em)`} px="4">
                {headers.map((header, i) => (
                    <GridItem key={header + i} colSpan={1} pos="relative">
                        <Text textStyle="columnName">{header}</Text>
                        {!listItems.length && <Divider pos="absolute" bottom="-2px" />}
                    </GridItem>
                ))}

                {listItems.length ? (
                    listItems.map(({ columnValues, actions }) =>
                        columnValues.map((value: React.ReactNode | string, i: number) => (
                            <GridItem key={i} colSpan={1} pos="relative">
                                <Divider pos="absolute" top="0" />
                                <HStack h="100%">
                                    <Flex align="center" h="100%" py="2" px="2" w="100%">
                                        {value}
                                    </Flex>
                                    {i === columnValues.length - 1 && actions?.length && (
                                        <>
                                            <Spacer />
                                            <ActionPopover actions={actions} />
                                        </>
                                    )}
                                </HStack>
                            </GridItem>
                        )),
                    )
                ) : (
                    <GridItem colSpan={headers.length} rowSpan={2} align="center" mt="12" mb="4">
                        {emptyMessage}
                    </GridItem>
                )}
            </Grid>
        </Box>
    </Box>
);
