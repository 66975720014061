import { Center, Text } from '@chakra-ui/layout';
import { Button, Divider, Flex, HStack, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BullaClaimInfo, BullaInfo } from '../../../data-lib/data-transforms';
import { useUserData } from '../../../hooks/useUserData';
import { useUserSummary } from '../../../hooks/useUserSummary';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { CreateClaimModal } from '../../modals/create-claim-modal';
import BullaView, { BullaBalanceSummary, BullaSelector, TabSwitcher } from './bulla-view';

export default () => {
    const history = useHistory();
    const { bullaId, tab } = useParams<{ bullaId: string; tab?: 'invoices' | 'remittances' }>();
    const { bullas } = useUserData();
    const { payableTotals, receivableTotals, netBalance } = useUserSummary();

    const bulla = bullas.find(bulla => bulla.bullaId === Number(bullaId));
    const selectBulla = ({ bullaId }: BullaInfo) => history.push({ pathname: `/bulla/${bullaId}/${tab || 'invoices'}` });
    const setTab = (tab: string) => history.replace({ pathname: `/bulla/${bullaId}/${tab}` });
    const tabToClaimType = tab === 'remittances' ? 'Remittance' : 'Invoice';
    const tabFilter = (claim: BullaClaimInfo) => claim.claimType === tabToClaimType;

    return (
        <>
            {bulla ? (
                <>
                    <SummaryPanel bg="#D9D7D5" px="12" pt="4">
                        <Flex>
                            <BullaSelector bullas={bullas} onSelect={selectBulla} initialBulla={bulla} />
                            <Spacer />
                            <HStack spacing="8">
                                <BullaBalanceSummary total={payableTotals.total} label="Payable" />
                                <BullaBalanceSummary total={receivableTotals.total} label="Receivable" />
                                <BullaBalanceSummary total={netBalance.balance} label="Net Balance" />
                            </HStack>
                        </Flex>
                    </SummaryPanel>
                    <MaxWidthWrapper>
                        <Stack pos="relative" px="8" spacing="0">
                            <TabSwitcher
                                tab={tab || 'invoices'}
                                setTab={setTab}
                                options={[
                                    { label: 'Invoices', value: 'invoices' },
                                    { label: 'Remittances', value: 'remittances' },
                                ]}
                            />
                            <Divider transform="translate(0, -2px)" />
                        </Stack>
                        <BullaView
                            claims={bulla.bullaClaims}
                            filters={[tabFilter]}
                            actionButton={
                                <CreateClaimModal
                                    bullaId={bullaId}
                                    claimType={tabToClaimType}
                                    triggerElement={onOpen => (
                                        <Button
                                            data-testid={`create${tabToClaimType}Button`}
                                            onClick={onOpen}
                                            colorScheme={'accent'}
                                            h="12"
                                            p="6"
                                            w="52"
                                        >
                                            <Text fontWeight="700">Create {tabToClaimType}</Text>
                                        </Button>
                                    )}
                                />
                            }
                        />
                    </MaxWidthWrapper>
                </>
            ) : (
                <Center h="60vh">
                    <Text textStyle="faint">You are either unauthorized or this bulla does not exist.</Text>
                </Center>
            )}
        </>
    );
};
