import { ChevronDownIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Center, Circle, Flex, Heading, HStack, Link, LinkProps, SimpleGrid, Spacer, VStack, Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useWeb3 } from '../../hooks/useWeb3';
import { WalletInfo } from '../display/wallet-info';

const Dot = () => <Circle bg="scheme.light" m="px" h="1" w="1" />;
const DotMenu = () => (
    <Center w="16">
        <SimpleGrid columns={3}>
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
        </SimpleGrid>
        <ChevronDownIcon color="scheme.light" />
    </Center>
);

const Logo = () => (
    <>
        <Heading fontSize="20px" textStyle="light" fontWeight="400">
            Bulla
        </Heading>
        <Heading fontSize="20px" color="scheme.accent_dark">
            BANKER
        </Heading>
    </>
);

interface NavButtonProps extends LinkProps {
    children: React.ReactNode;
    to: string;
}

export const NavButton = ({ children, to, ...props }: NavButtonProps) => {
    const location = useLocation();
    const isSelected =
        to === '/' && !location.pathname.split('/')[1] ? true : to !== '/' && location.pathname.includes(to.split('/')[1]) ? true : false;

    return (
        <Link
            as={NavLink}
            to={to}
            isselected={String(isSelected)} //TODO: fix for '/'
            {...props}
        >
            {children}
        </Link>
    );
};

const Navigation = () => {
    const { wallet } = useWeb3();
    return (
        <HStack spacing="8">
            {wallet && (
                <>
                    <NavButton to={'/'} data-testid="home-link">
                        <>Home</>
                    </NavButton>
                    <NavButton to={'/payables/invoices'} data-testid="payables-link">
                        <>Payables</>
                    </NavButton>
                    <NavButton to={'/receivables/invoices'} data-testid="receivables-link">
                        <>Receivables</>
                    </NavButton>
                </>
            )}
        </HStack>
    );
};

export default () => (
    <VStack bg="headerBg">
        <Flex bg="tomato" w="100%" align="center" mb={'-.5em'} justify="center" color="white">
            <Text>This is a pre-alpha preview and any historical data will not be migrated</Text>
            <Text as="button" mx="2" color="gray.200" onClick={() => window.open(`https://banker.bulla.network/`, '_blank')}>
                Please use v1 <ExternalLinkIcon mt={'-1'} />
            </Text>
        </Flex>
        <Flex as="header" w="100%" minH="16" align="center" m="0" py="4" bg="headerBg" maxW="pageMax">
            {/* <DotMenu /> */}
            <Logo />
            <Box w="16" />
            <Navigation />
            <Spacer />
            <WalletInfo />
        </Flex>
    </VStack>
);
