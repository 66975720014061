import { Box } from '@chakra-ui/react';
import React from 'react';
import { ClaimStatus } from '../../data-lib/data-transforms';
import { ChakraCompose } from '../../tools/types';

export const getStatusBadge = (claimStatus: ClaimStatus, isPayable: boolean): React.ReactNode => {
    const Badge = ({ children, ...overrides }: ChakraCompose) => (
        <Box color="scheme.light" textStyle="badge" {...overrides}>
            {children}
        </Box>
    );
    const badges = {
        Pending: (
            <Badge maxW="fit-content" bg="badgeColors.awaitng" data-testid="statusBadge-pending">
                {isPayable ? 'Payment Due' : 'Awaiting Payment'}
            </Badge>
        ),
        Repaying: (
            <Badge maxW="fit-content" bg="badgeColors.awaitng">
                Repaying
            </Badge>
        ),
        Paid: (
            <Badge maxW="fit-content" bg="badgeColors.paid" data-testid="statusBadge-paid">
                Paid
            </Badge>
        ),
        Rejected: (
            <Badge maxW="fit-content" bg="badgeColors.rejected" data-testid="statusBadge-rejected">
                Rejected
            </Badge>
        ),
        Rescinded: (
            <Badge maxW="fit-content" bg="badgeColors.rescinded" color="scheme.dark" data-testid="statusBadge-rescinded">
                Rescinded
            </Badge>
        ),
    };
    return badges[claimStatus];
};
