import { Box, Icon, IconButton, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';

export const OverflowIcon = ({ color }: { color: string }) => (
    <Icon viewBox="0 0 512 512" color={color}>
        <path
            fill={color}
            d="M315.1,452.9c0,32.6-26.5,59.1-59.1,59.1s-59.1-26.5-59.1-59.1s26.5-59.1,59.1-59.1S315.1,420.3,315.1,452.9z
      M315.1,256c0,32.6-26.5,59.1-59.1,59.1s-59.1-26.5-59.1-59.1s26.5-59.1,59.1-59.1S315.1,223.4,315.1,256z M315.1,59.1
      c0,32.6-26.5,59.1-59.1,59.1s-59.1-26.5-59.1-59.1S223.4,0,256,0S315.1,26.5,315.1,59.1z"
        />
    </Icon>
);
export const PopoverAction = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
    <Box
        as="button"
        p={4}
        w="100%"
        textStyle="dark"
        textAlign="start"
        _hover={{ bg: 'scheme.accent_light', fontWeight: 500 }}
        onClick={onClick}
    >
        {children}
    </Box>
);

export const ActionPopover = ({ actions }: { actions: React.ReactNode[] }) => (
    <Popover placement="bottom-end" autoFocus>
        <PopoverTrigger>
            <IconButton
                aria-label="actions"
                colorScheme={'whiteAlpha'}
                _hover={{ bg: 'scheme.accent_light' }}
                icon={<OverflowIcon color={'icon_dark'} />}
            />
        </PopoverTrigger>
        <PopoverContent boxShadow="xl" borderRadius="2xl" matchWidth={true} w="16em" overflow="hidden" autoFocus>
            {/* TODO: know issue here where box shadow is hidden until focus shifts to the popover (i.e: clicking)*/}
            <Box>
                {actions.map((element, i) => (
                    <React.Fragment key={i}>{element}</React.Fragment>
                ))}
            </Box>
        </PopoverContent>
    </Popover>
);
