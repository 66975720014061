import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        accent: {
            100: '#FFFFFF',
            200: '#E9C9B5',
            300: '#D8A17E',
            400: '#c87948',
            500: '#B46C40',
            600: '#8C5432',
            700: '#643C23',
            800: '#23130e',
        },
        dark: {
            100: '#a7a09e',
            200: '#7b716e',
            300: '#4e423e',
            400: '#23130e',
            500: '#1f110c',
            600: '#180d09',
            700: '#110907',
        },
        orange: {
            100: '#eac5bd',
            200: '#dfa89c',
            300: '#d58b7a',
            400: '#CB6E5A',
            500: '#a25848',
            600: '#794236',
            700: '#512c24',
        },
        scheme: {
            light: '#FFFFFF',
            dark: '#1A1512',
            accent_dark: '#C87948',
            accent_light: '#F3F3F2',
        },
        badgeColors: {
            paid: '#81A778',
            rescinded: '#E0E1E2',
            awaitng: '#8A827A',
            rejected: '#CB6E5A',
        },
        walletInfoBg: '#2C2725',
        heading: '#462A1D',
        headerBg: '#1B1511',
        footerBg: '#DEDCDA',
        highlight: '#CF8452',
        icon_dark: '#4E393B',
    },
    textStyles: {
        labelXl: {
            fontSize: '2em',
            lineHeight: '2em',
            fontWeight: 400,
        },
        labelLg: {
            fontSize: '1.5em',
            fontWeight: 800,
        },
        noWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        bullaViewHeader: {
            fontSize: '1.75em',
            color: 'icon_dark',
        },
        listTitle: {
            fontSize: '1.5em',
            color: '#462A1D',
            fontWeight: 700,
        },
        columnName: {
            fontSize: '.9em',
            margin: '2',
            textTransform: 'uppercase',
            fontWeight: 700,
        },
        uppercase: {
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        caption: {
            color: '#CF8452',
            fontStyle: 'italic',
            fontSize: 'xs',
        },
        badge: {
            fontSize: '.8em',
            py: 1,
            px: 2,
            borderRadius: 6,
        },
        cell: {
            textStyle: 'dark',
        },
        dark: {
            color: '#23130E',
        },
        light: {
            color: '#FFFFFF',
        },
        faint: {
            color: '#8A827A',
        },
        underline: {
            textDecoration: 'underline',
        },
        link: {
            fontWeight: '500',
            textDecoration: 'underline',
        },
        hover: {
            textShadow: '-0.01ex 0 black, 0.01ex 0 black',
        },
    },
    sizes: {
        pageMax: '1600px',
    },
    styles: {
        global: {
            header: {
                color: '#FFFFFF',
            },
            hr: {
                w: '100%',
                h: '1px',
                bg: '#E3E3E3',
            },
        },
    },
    components: {
        Link: {
            baseStyle: (props: { isselected?: string }) => ({
                fontWeight: props.isselected === 'true' ? 700 : 400,
                fontSize: '.9em',
                lineHeight: '24px',
            }),
        },
        Popover: {
            baseStyle: {
                popper: {
                    width: 'fit-content',
                    maxWidth: 'fit-content',
                },
            },
        },
    },
    fonts: {
        heading: 'Rubik',
        body: 'Inter',
    },
});

export default theme;
