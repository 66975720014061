import {
    Box,
    Button,
    ChakraStyleProps,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useCreateBulla } from '../../hooks/useCreateBulla';

interface CreateBullaModalProps extends ChakraStyleProps {
    triggerElement: (onOpen: () => void) => React.ReactNode;
}

export const CreateBullaModal = ({ triggerElement }: CreateBullaModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const [bullaName, setBullaName] = React.useState('');
    const [creating, create] = useCreateBulla();

    const isBullaNameInvalid = (name: string) => !!name;

    const handleCreateBulla = async () => {
        let receipt = await create({ description: bullaName });
        if (receipt) {
            if (isOpen) onClose();
            setBullaName('');
        }
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal
                closeOnOverlayClick={false}
                isCentered
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Text color="icon_dark" textStyle="labelLg">
                            New Bulla
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl isRequired>
                            <FormLabel>Bulla Name</FormLabel>
                            <Input
                                ref={initialRef}
                                placeholder="e.g. Your name or company name..."
                                value={bullaName}
                                onChange={e => setBullaName(e.target.value)}
                                data-testid="bullaNameInput"
                            />
                        </FormControl>
                        <Box h="4" />
                        <Text textStyle="caption" px="4">
                            This name will be associated with your Bulla token in all transactions with other parties.
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button fontWeight="500" colorScheme="white" color="dark" border="1px" borderColor="dark" onClick={onClose}>
                            Cancel
                        </Button>
                        <Spacer />
                        <Button
                            fontWeight="500"
                            _hover={{ bg: 'dark.300' }}
                            bg="dark.400"
                            _disabled={{ bg: 'dark.200' }}
                            textStyle="light"
                            disabled={!isBullaNameInvalid(bullaName) || creating}
                            isLoading={creating}
                            onClick={handleCreateBulla}
                            data-testid="triggerCreateBulla"
                        >
                            Create Bulla
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
