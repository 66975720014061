import { Provider } from '@ethersproject/abstract-provider';
import { Signer } from 'ethers';
import { BullaGroup } from '../../artifacts/typechain/BullaGroup';
import { SetPendingTxn } from '../state/ui-state';
import { EthAddress, fromEther } from './ethereum';
import { mapToNewBullaClaimEvent, mapToNewBullaEvent } from './event-mapping';
import { dateToInt } from './helpers';

export const joinGroup = async (bullaGroup: BullaGroup, signer: Signer) => await bullaGroup.connect(signer).joinGroup();

export const leaveGroup = async (bullaGroup: BullaGroup, signer: Signer) => await bullaGroup.connect(signer).joinGroup();

export const checkMembership = async (address: EthAddress, bullaGroup: BullaGroup, provider: Provider) =>
    await bullaGroup.connect(provider).isMember(address);

export type Bulla = {
    bullaId: number;
    owner: EthAddress;
};
type CreateBullaParams = {
    description: string;
    ownerFunding?: number;
    bullaGroup: BullaGroup;
    signer: Signer;
};
export const createBulla = async (
    setPendingTxn: SetPendingTxn,
    { description, ownerFunding = 0, bullaGroup, signer }: CreateBullaParams,
) => {
    const tx = await bullaGroup.connect(signer).createBulla(description, ownerFunding);
    setPendingTxn({ hash: tx.hash, pending: true });
    const receipt = await tx.wait();
    const eventLog = receipt.events?.find(e => e.event == 'NewBulla');
    return {
        newBullaEvent: mapToNewBullaEvent(eventLog?.args),
        receipt: receipt,
    };
};

type CreateBullaClaimParams = {
    bullaId: number;
    claimAmount: number;
    creditor: EthAddress;
    debtor: EthAddress;
    description: string;
    dueBy?: Date;
    bullaGroup: BullaGroup;
    signer: Signer;
};
export const createBullaClaim = async (setPendingTxn: SetPendingTxn, params: CreateBullaClaimParams) => {
    const { bullaId, claimAmount, creditor, debtor, description, dueBy, bullaGroup, signer } = params;

    const tx = await bullaGroup
        .connect(signer)
        .createBullaClaim(bullaId, fromEther(claimAmount), creditor, debtor, description, dueBy ? dateToInt(dueBy) : 0);
    setPendingTxn({ hash: tx.hash, pending: true });
    const receipt = await tx.wait();
    const eventLog = receipt.events?.find(e => e.event == 'NewBullaClaim');
    return {
        receipt: receipt,
        newBullaClaimEvent: mapToNewBullaClaimEvent(eventLog?.args),
    };
};
