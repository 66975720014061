import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    ModalHeader,
    Skeleton,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import ShareIcon from 'url:../../../assets/share.svg';
import { BullaClaimInfo } from '../../../data-lib/data-transforms';
import { addressEquality } from '../../../data-lib/ethereum';
import { useUserData } from '../../../hooks/useUserData';
import { useWeb3 } from '../../../hooks/useWeb3';
import { toDateDisplay } from '../../../tools/common';
import { ChakraCompose } from '../../../tools/types';
import { AddressLabel } from '../../base/address-label';
import CopyElement from '../../base/copy-element';
import { getStatusBadge } from '../../base/status-badge';
import { Currency } from '../../currency/currency';
import { CancelButton, PayButton } from '../../display/claim-action-buttons';
import { FileUpload } from './claim-ipfs-file';

export type ClaimDetailsProps = { claim?: BullaClaimInfo; handleClose: () => void; isRemittancePopup?: boolean };
export type WithSkeletonProps = { children: JSX.Element; show: boolean; randomW?: boolean };
const randomWidth = (min: number, max: number, property: string) => {
    return `${Math.random() * (max - min) + min}${property}`;
};

export const getClaimRelationToUser = (userAddress: string, { claimType, creditor, debtor } = emptyClaim) => {
    const [sender, receiver] = claimType === 'Invoice' ? [creditor, debtor] : [debtor, creditor];
    const isPayable = addressEquality(userAddress, debtor);
    return { sender, receiver, isPayable };
};

export const ReceiptBox = ({ header, children }: { header: string; children: React.ReactNode }) => (
    <Box borderRadius="lg" borderWidth="1px" w="100%" p="4" pos="relative" my="2">
        <Text textStyle="uppercase" pos="absolute" top="-3" px="2" bg="white">
            {header}
        </Text>
        {children}
    </Box>
);

export const InvoiceDesc = ({ children, ...overrides }: ChakraCompose) => (
    <GridItem {...overrides}>
        <Box fontWeight="500" w="100%">
            {children}
        </Box>
    </GridItem>
);

export const WithSkeleton = React.memo(({ children, show, randomW }: WithSkeletonProps) => {
    const width = React.useRef(randomWidth(50, 250, 'px'));
    return <>{show ? <Skeleton h="1.5em" maxW="100%" w={randomW ? width.current : '100%'} /> : children}</>;
});

const emptyClaim = {
    created: new Date(),
    bullaDescription: '',
    claimAddress: '',
    description: '',
    claimStatus: 'Pending',
    creditor: '',
    debtor: '',
    claimOwner: '',
    claimAmount: 0,
    paidAmount: 0,
    claimType: 'Remittance',
};

export const ClaimDetails = ({ claim = emptyClaim as BullaClaimInfo, handleClose, isRemittancePopup }: ClaimDetailsProps) => {
    const { userAddress } = useUserData();
    const { network } = useWeb3();
    const { dueBy, bullaDescription, claimAddress, description, claimStatus, claimAmount, paidAmount, claimType } = claim;
    const { isPayable, receiver, sender } = getClaimRelationToUser(userAddress, claim);
    const getUrl = () => `https://banker.bulla.network/#/?claim=${claimAddress}&network=${network}`;

    return (
        <>
            <ModalHeader>
                <Heading color="icon_dark" textStyle="labelLg">
                    {claimType} Details
                </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <Stack minW="fit-content" maxW="60%">
                    <Text color="headerBg" textStyle="uppercase">
                        Overview
                    </Text>
                    <Grid templateColumns={'auto auto'} gap="2">
                        <InvoiceDesc>Due Date</InvoiceDesc>
                        <WithSkeleton children={<InvoiceDesc>{toDateDisplay(dueBy)}</InvoiceDesc>} show={!claim} randomW />
                        <InvoiceDesc>Claim Address</InvoiceDesc>
                        <WithSkeleton
                            children={
                                <InvoiceDesc>
                                    <AddressLabel ethAddress={claimAddress} />
                                </InvoiceDesc>
                            }
                            show={!claim}
                        />
                        <InvoiceDesc>Bulla Name</InvoiceDesc>
                        <WithSkeleton children={<InvoiceDesc>{bullaDescription}</InvoiceDesc>} show={!claim} randomW />
                        <InvoiceDesc>Description</InvoiceDesc>
                        <WithSkeleton children={<InvoiceDesc>{description}</InvoiceDesc>} show={!claim} randomW />
                        <InvoiceDesc>File</InvoiceDesc>
                        <WithSkeleton children={<InvoiceDesc>{claim && <FileUpload claim={claim} />}</InvoiceDesc>} show={!claim} randomW />
                        <InvoiceDesc>Status</InvoiceDesc>
                        <WithSkeleton
                            children={<GridItem>{claim && getStatusBadge(claimStatus, isPayable)}</GridItem>}
                            show={!claim}
                            randomW
                        />
                    </Grid>
                </Stack>
                <Box h="8" />
                <Flex wrap="nowrap">
                    <ReceiptBox header="sender">
                        <Text fontWeight="700">Wallet</Text>
                        <WithSkeleton children={<AddressLabel ethAddress={sender} />} show={!claim} />
                    </ReceiptBox>
                    <Box w="6" />
                    <ReceiptBox header="receiver">
                        <Text fontWeight="700">Wallet</Text>
                        <WithSkeleton children={<AddressLabel ethAddress={receiver} fontWeight="500" />} show={!claim} />
                    </ReceiptBox>
                </Flex>
                <Box h="8" />
                <HStack>
                    <Spacer />
                    <Grid templateColumns={'min-content minmax(10em, min-content)'} gap="2">
                        <InvoiceDesc>Requested</InvoiceDesc>
                        <InvoiceDesc justifySelf="end" pr="4">
                            <WithSkeleton children={<Currency value={claimAmount} />} show={!claim} randomW />
                        </InvoiceDesc>
                        <InvoiceDesc>Received</InvoiceDesc>
                        <InvoiceDesc justifySelf="end" pr="4">
                            <WithSkeleton children={<Currency value={paidAmount} />} show={!claim} randomW />
                        </InvoiceDesc>
                        <GridItem colSpan={2}>
                            <Divider />
                        </GridItem>
                        <InvoiceDesc>Outstanding</InvoiceDesc>
                        <InvoiceDesc justifySelf="end" pr="4">
                            <WithSkeleton children={<Currency value={claimAmount - paidAmount} />} show={!claim} randomW />
                        </InvoiceDesc>
                    </Grid>
                </HStack>
                <Box h="12" />
            </ModalBody>
            <ModalFooter>
                <HStack spacing="4" w="100%" justify="center">
                    {/* <Button
                    colorScheme="white"
                    color="dark"
                    border="1px"
                    borderColor="dark"
                    w="28"
                    py="6"
                    fontWeight="500"
                >
                    View Log
                </Button> */}
                    {isRemittancePopup ? (
                        <>
                            <Button
                                colorScheme="white"
                                color="dark"
                                border="1px"
                                borderColor="dark"
                                px="8"
                                py="6"
                                onClick={handleClose}
                                isDisabled={claimStatus === 'Paid'}
                                data-testid="payLaterButton"
                            >
                                Pay Later
                            </Button>
                            <Spacer />
                            <ButtonGroup spacing="4">
                                <CancelButton selectedClaim={claimAddress} />
                                <PayButton selectedClaim={claimAddress} />
                            </ButtonGroup>
                        </>
                    ) : (
                        <>
                            <ButtonGroup spacing="4">
                                <CancelButton selectedClaim={claimAddress} />
                                {isPayable && <PayButton selectedClaim={claimAddress} />}
                            </ButtonGroup>
                            <Spacer />
                            <CopyElement valueToCopy={getUrl()}>
                                <Button variant="link" textStyle="dark" fontWeight="500" rightIcon={<Image ml={-1} src={ShareIcon} />}>
                                    Share Claim
                                </Button>
                            </CopyElement>

                            <Button px="8" py="6" fontWeight="500" colorScheme="dark" onClick={handleClose}>
                                Close
                            </Button>
                        </>
                    )}
                </HStack>
            </ModalFooter>
        </>
    );
};
