import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, CloseButton, Divider, Fade, Flex, HStack, SlideFade, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { transactionUri } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import release from '../../release';
import { useUIState } from '../../state/ui-state';
import { ChakraCompose } from '../../tools/types';
import { ClaimDetailsModal } from '../modals/claim-details-modal/claim-details-modal';
import Header from './header';

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    React.useEffect(() => window.scrollTo(0, 0), [pathname]);
    return null;
};

export const SummaryPanel = ({ children, ...overrides }: ChakraCompose) => (
    <MaxWidthWrapper bg={'scheme.accent_light'} w="100%" px="8" pt="8" pb="4" {...overrides}>
        {children}
    </MaxWidthWrapper>
);

export const MaxWidthWrapper = ({ children, ...props }: ChakraCompose) => (
    <Box w="100%" align="center" {...props}>
        <Box maxW="pageMax" align="left">
            {children}
        </Box>
    </Box>
);

export const LoadingScreen = ({
    isLoading,
    hasFailed,
    errorMessage,
}: {
    isLoading: boolean;
    hasFailed: boolean;
    errorMessage: string | undefined;
}) => {
    return (
        <SlideFade in={isLoading}>
            <Flex h="80vh" w="100%" direction="column" align="center" justify="center" display={isLoading ? 'flex' : 'none'}>
                <SlideFade in={!hasFailed}>
                    <Text>Loading the Bulla Network...</Text>
                </SlideFade>
                <SlideFade in={hasFailed}>
                    <Stack>
                        <Text>An error occured...</Text>
                        <Text>{errorMessage}</Text>
                        <Text as="button" fontWeight="500" textStyle="underline" onClick={() => window.location.reload()}>
                            Refresh
                        </Text>
                    </Stack>
                </SlideFade>
            </Flex>
        </SlideFade>
    );
};

export type ToastStates = 'Pending' | 'Complete' | 'Failed' | undefined;

export const TxnToast = () => {
    const { pendingTxn, setPendingTxn } = useUIState();
    const { pending, failed, receipt, hash } = pendingTxn;
    const toastState: ToastStates = failed?.hasFailed ? 'Failed' : !!receipt ? 'Complete' : pending ? 'Pending' : undefined;
    const { network } = useWeb3();
    React.useEffect(() => {
        if (receipt) setTimeout(() => setPendingTxn({ pending: false }), 9000);
    }, [receipt]);
    const close = () => setPendingTxn({ pending: false });

    const toastElements = {
        Pending: {
            bg: 'blue.500',
            text: <Text data-testid="transactionPending">Transaction Pending</Text>,
            icon: <Spinner />,
        },
        Complete: {
            bg: 'green.500',
            text: <Text data-testid="transactionComplete">Transaction Complete</Text>,
            icon: <CheckIcon />,
        },
        Failed: {
            bg: 'red.500',
            text: <Text data-testid="transactionFailed">Transaction Failed</Text>,
            icon: <CloseIcon />,
        },
    };

    return (
        <Fade in={!!toastState}>
            {toastState && (
                <Box
                    pos="fixed"
                    color="white"
                    align="center"
                    p={3}
                    bg={toastElements[toastState].bg}
                    borderRadius="lg"
                    shadow="md"
                    left="50%"
                    transform="translate(-50%)"
                    top="4"
                    minW="72"
                    maxW="70vw"
                    zIndex="10000"
                >
                    <CloseButton size="sm" onClick={close} pos="absolute" right="2" top="1" />
                    <HStack justify="center" p="1">
                        {toastElements[toastState].icon}
                        {toastElements[toastState].text}
                    </HStack>
                    {(hash || toastState === 'Failed') && <Divider my="1" />}
                    {toastState === 'Failed' && <Text>{failed?.reason}</Text>}
                    {hash && network !== undefined && (
                        <Text as="button" onClick={() => window.open(transactionUri(hash, network))} fontWeight="500" textStyle="underline">
                            View Transaction Etherscan
                        </Text>
                    )}
                </Box>
            )}
        </Fade>
    );
};

export const Footer = () => {
    return (
        <MaxWidthWrapper bg="footerBg" pos="absolute" bottom="0">
            <Flex bg="footerBg" p="4" h="20" w="100%" align="center">
                <Text>Copyright 2021 Bulla Protocol. All Rights Reserved</Text>
                <Spacer />
                <Text textStyle="faint">Release: {release}</Text>
                {/* <Spacer /> */}
                {/* <NavButton to="/terms">Terms & Conditions</NavButton>
                <Text m="2">|</Text>
                <NavButton to="/privacy">Privacy Policy</NavButton>
                <Text m="2">|</Text>
                <NavButton to="/support">Support</NavButton>
                <Text m="2">|</Text>
                <NavButton to="/help">Help</NavButton> */}
            </Flex>
        </MaxWidthWrapper>
    );
};

export const PageLayout = ({ children, isLoading }: { children: React.ReactNode; isLoading: boolean }) => (
    <Box overflow="hidden auto">
        {/*this ^^ might cause issues in the future */}
        <Box minH="100vh" maxW="100vw" pb="20" mr="calc(100% - 100vw)">
            <TxnToast />
            <ScrollToTop />
            <ClaimDetailsModal />
            {/* ^^ utility components */}

            <Header />
            {isLoading ? (
                <Flex h="80vh" align="center" justify="center">
                    <Spinner />
                </Flex>
            ) : (
                children
            )}
            <Footer />
        </Box>
    </Box>
);
