import { ContractTransaction, Signer } from 'ethers';
import { BullaClaim } from '../../artifacts/typechain/BullaClaim';
import { fromEther } from './ethereum';
import { mapToClaimActionEvent, mapToFeePaidEvent, mapToMultihashAddedEvent } from './event-mapping';
import { getBytes32FromMultihash } from './mutlihash';

export enum ClaimStatus {
    Pending,
    Repaying,
    Paid,
    Rejected,
    Rescinded,
}

export enum RejectReason {
    None,
    UnknownAddress,
    DisputedClaim,
    SuspectedFraud,
    Other,
}

type ActionParams = {
    bullaClaim: BullaClaim;
    paymentAmount?: number;
    rejectReason?: RejectReason;
    signer: Signer;
};

export type TxCallback = (tx: ContractTransaction) => void;

export const payClaim = async (txCallback: TxCallback, { bullaClaim, paymentAmount = 0, signer }: ActionParams) => {
    const tx = await bullaClaim.connect(signer).payClaim({ value: fromEther(paymentAmount) });
    txCallback(tx);
    const receipt = await tx.wait();
    const claimActionEvent = receipt.events?.find(e => e.event == 'ClaimAction');
    const feePaidEvent = receipt.events?.find(e => e.event == 'FeePaid');

    return {
        claimActionEvent: mapToClaimActionEvent(claimActionEvent?.args),
        feePaidEvent: mapToFeePaidEvent(feePaidEvent?.args),
        receipt: receipt,
    };
};

export const rejectClaim = async (txCallback: TxCallback, { rejectReason = RejectReason.None, bullaClaim, signer }: ActionParams) => {
    const tx = await bullaClaim.connect(signer).rejectClaim(rejectReason);
    txCallback(tx);
    const receipt = await tx.wait();
    const claimActionEvent = receipt.events?.find(e => e.event == 'ClaimAction');

    return {
        claimActionEvent: mapToClaimActionEvent(claimActionEvent?.args),
        receipt: receipt,
    };
};

export const rescindClaim = async (txCallback: TxCallback, { bullaClaim, signer }: ActionParams) => {
    const tx = await bullaClaim.connect(signer).rescindClaim();
    txCallback(tx);
    const receipt = await tx.wait();
    const claimActionEvent = receipt.events?.find(e => e.event == 'ClaimAction');

    return {
        claimActionEvent: mapToClaimActionEvent(claimActionEvent?.args),
        receipt: receipt,
    };
};

export const addMultihash = async (
    txCallback: TxCallback,
    { bullaClaim, ipfsHash, signer }: { bullaClaim: BullaClaim; ipfsHash: string; signer: Signer },
) => {
    const { digest, hashFunction, size } = getBytes32FromMultihash(ipfsHash);
    const tx = await bullaClaim.connect(signer).addMultihash(digest, hashFunction, size);
    txCallback(tx);
    const receipt = await tx.wait();
    const claimActionEvent = receipt.events?.find(e => e.event == 'MultihashAdded');

    return {
        multihashAddedEvent: mapToMultihashAddedEvent(claimActionEvent?.args),
        receipt,
    };
};

//TODO: Add transfer related functions
