import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useCloseClaim } from '../../../hooks/useClaimDetailDisclosure';
import { useQuery } from '../../../hooks/useQuery';
import { useUserData } from '../../../hooks/useUserData';
import { useWeb3 } from '../../../hooks/useWeb3';
import { ClaimDetails } from './claim-details';
import { ClaimNotFound } from './claim-not-found';

export const ClaimDetailsModal = () => {
    const initialRef = React.useRef(null);
    const { findClaim } = useUserData();
    const removeClaimFromParams = useCloseClaim();
    const { onClose } = useDisclosure();
    const { address } = useWeb3();
    const requestedClaimAddress = useQuery('claim') ?? '';
    const claim = findClaim(requestedClaimAddress);
    const isOpen = !!requestedClaimAddress && !!address;

    const handleClose = () => {
        if (isOpen) {
            onClose();
            removeClaimFromParams();
        }
    };

    return (
        <>
            <Modal
                isCentered
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={handleClose}
                size="2xl"
                closeOnOverlayClick={false}
                closeOnEsc
            >
                <ModalOverlay />
                <ModalContent py="4" px="2">
                    {claim ? <ClaimDetails claim={claim} handleClose={handleClose} /> : <ClaimNotFound handleClose={handleClose} />}
                </ModalContent>
            </Modal>
        </>
    );
};
